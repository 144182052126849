/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react';
import { Text, TouchableOpacity, View, Linking, Image, Platform } from 'react-native';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import * as Animatable from 'react-native-animatable';
import {
  getTrackingPermissionsAsync,
  requestTrackingPermissionsAsync,
} from 'expo-tracking-transparency';

import { CustomBottomSheetModal } from '../../../common/components/CustomBottomSheetModal';
import { Header } from '../../../common/components/Header';
import { Layout } from '../../../common/components/Layout';
import { isWeb } from '../../../common/constants';
import { RootTabRoutes } from '../../../common/routes/routes';
import { useUserState } from '../../../common/state';
import { tw } from '../../../common/styles/tailwind';
import { HorizontalLine } from '../../profile/components/HorizontalLine';
import { ImageComponent } from '../components/ImageComponent';
import { InfoBlockWrapper } from '../components/InfoBlockWrapper';
import { ContactInformation } from '../components/ContactInformation';
import { ArrowIcon } from '../../../common/components/icons/ArrowIcon';
import { LinearGradient } from 'expo-linear-gradient';
import * as WebBrowser from 'expo-web-browser';
import { useHaptic } from '../../../common/hooks/useHaptic';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';

enum BottomSheetType {
  COMPANY = 'company',
  MANAGER = 'manager',
}

export const HomeScreen = () => {
  const { locale } = useLocaleContext();

  const COMPANY_INFO = {
    title: `${locale.get('home.team')}`,
    phone: '95901982',
    email: 'hei@andhamlet.no',
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bottomSheetInfo, changeBottomSheetInfo] = useState(COMPANY_INFO);
  const { userLimits, userInfo, houseInfo, setTrackingEnabled } = useUserState((state) => state);
  const isOwner = userInfo?.house_id && userInfo.house_share && userLimits;

  useEffect(() => {
    if (Platform.OS === 'ios') {
      checkTrackingPermissions();
    }
  }, []);

  if (!isOwner) {
    return <ContactInformation />;
  }

  const handleShowContacts = (type: BottomSheetType) => {
    useHaptic().success();
    let info = COMPANY_INFO;

    if (type === BottomSheetType.MANAGER) {
      info = {
        title: locale.get('home.manager'),
        phone: String(houseInfo?.house_manager.phone_number) || '',
        email: houseInfo?.house_manager.email || '',
      };
    }

    changeBottomSheetInfo(info);
    setIsModalVisible(true);
  };

  const openExploreLink = async () => {
    if (!houseInfo?.explore_url) return;
    try {
      await WebBrowser.openBrowserAsync(houseInfo.explore_url);
    } catch (error) {}
  };

  const checkTrackingPermissions = async () => {
    const { granted } = await getTrackingPermissionsAsync();
    setTrackingEnabled(granted);
    if (granted) return;

    const { status } = await requestTrackingPermissionsAsync();
    setTrackingEnabled(status === 'granted');
  };

  return (
    <Animatable.View useNativeDriver duration={1000} animation="fadeIn" style={tw`w-full h-full`}>
      {isWeb && (
        <Header
          title={locale.get('home.header_title')}
          showNavigationButtons
          activeButtonRoute={RootTabRoutes.HOME_ROOT}
          showAllButtons={userInfo?.house_id && userInfo?.house_share}
        />
      )}
      <CustomBottomSheetModal
        closeModal={() => setIsModalVisible(false)}
        isVisible={isModalVisible}
        snapPoints={['20%', '40%']}
        title={bottomSheetInfo.title}
      >
        <View style={tw`px-4`}>
          {bottomSheetInfo?.phone && (
            <View style={tw`flex-row items-center mb-3`}>
              <MaterialIcons name="phone" size={26} color="black" />
              <TouchableOpacity onPress={() => Linking.openURL(`tel:${bottomSheetInfo.phone}`)}>
                <Text style={tw`ml-2 text-lg font-header underline`}>{bottomSheetInfo.phone}</Text>
              </TouchableOpacity>
            </View>
          )}
          {bottomSheetInfo?.email && (
            <View style={tw`flex-row items-center mt-2`}>
              <MaterialIcons name="mail" size={26} color="black" />
              <TouchableOpacity onPress={() => Linking.openURL(`mailto:${bottomSheetInfo.email}`)}>
                <Text style={tw`ml-2 text-lg font-header underline`}>{bottomSheetInfo.email}</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </CustomBottomSheetModal>
      <Layout
        scrollable
        withShadow
        contentContainerStyle={tw`z-2`}
        showsVerticalScrollIndicator={false}
      >
        <LinearGradient
          colors={['#FFF', '#F7F4F4']}
          style={tw`absolute h-full w-full bottom-0 z-0`}
        />
        <View
          style={{
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
          }}
        >
          <ImageComponent data={houseInfo?.house_images || []} />
        </View>
        {houseInfo?.name && houseInfo?.description && (
          <InfoBlockWrapper>
            <View style={tw`flex-row justify-between items-center mt-7 mb-7`}>
              <Text style={tw`text-[30px] font-base`}>{houseInfo.name}</Text>
            </View>
            {houseInfo?.shares_total && (
              <View style={tw`mb-2`}>
                <Text style={tw`mt-1.5 uppercase text-black font-header text-lg`}>
                  {locale.get('home.ownership', {
                    share: `${userInfo.house_share?.shares}/${houseInfo.shares_total}`,
                  })}
                </Text>
              </View>
            )}
          </InfoBlockWrapper>
        )}
        {houseInfo?.country && houseInfo?.location && houseInfo?.address && (
          <InfoBlockWrapper>
            <Text
              style={tw`font-header uppercase text-base`}
            >{`${houseInfo.country}, ${houseInfo.location}, ${houseInfo.address}`}</Text>
            <HorizontalLine style="mb-0.5" />
            <HorizontalLine style="mb-10" />
          </InfoBlockWrapper>
        )}
        <InfoBlockWrapper>
          <View style={tw`px-2 bg-thrid py-3`}>
            {(houseInfo?.house_manager.phone_number || houseInfo?.house_manager.email) && (
              <>
                <View style={tw`flex-row justify-between`}>
                  <Text style={tw`font-header text-base`}>{locale.get('home.home_manager')}</Text>
                  <TouchableOpacity
                    onPress={() => handleShowContacts(BottomSheetType.MANAGER)}
                    style={tw`flex-row`}
                    activeOpacity={0.8}
                  >
                    <Text style={tw`text-black font-header text-base uppercase`}>
                      {locale.get('home.contact_button')}
                    </Text>
                    <View style={tw`ml-1 w-6 h-6`}>
                      <ArrowIcon color={tw`text-black`.color as string} />
                    </View>
                  </TouchableOpacity>
                </View>
                <HorizontalLine style="mb-6" />
              </>
            )}
            <View style={tw`flex-row justify-between mt-2`}>
              <Text style={tw`font-header text-base`}>{locale.get('home.team')}</Text>
              <TouchableOpacity
                onPress={() => handleShowContacts(BottomSheetType.COMPANY)}
                style={tw`flex-row`}
                activeOpacity={0.8}
              >
                <Text style={tw`text-black font-header text-base uppercase`}>
                  {locale.get('home.contact_button')}
                </Text>
                <View style={tw`ml-1 w-6 h-6`}>
                  <ArrowIcon color={tw`text-black`.color as string} />
                </View>
              </TouchableOpacity>
            </View>
            <HorizontalLine />
          </View>
        </InfoBlockWrapper>
        <InfoBlockWrapper>
          <Text style={tw`mt-10 mb-2.5 text-black font-header text-lg`}>
            {locale.get('home.limits.title')}
          </Text>
          <View style={tw`flex py-3 flex-col`}>
            <Text style={tw`font-header text-base`}>
              {locale.get('home.limits.general', {
                value: `${userLimits.advanceStaysBooked}/${userLimits.advanceStaysLimit}`,
              })}
            </Text>
            <HorizontalLine />
            <Text style={tw`mt-5 font-header text-base`}>
              {locale.get('home.limits.special', {
                value: `${userLimits.specialDatesBooked}/${userLimits.specialDatesLimit}`,
              })}
            </Text>
            <HorizontalLine />
            {/* <Text style={tw`mt-5 font-header text-base`}>
              {userLimits.daysBooked}/{userLimits.daysLimit} Days
            </Text>
            <HorizontalLine /> */}
          </View>
        </InfoBlockWrapper>
        {!isWeb && houseInfo?.explore_url && (
          <InfoBlockWrapper>
            <TouchableOpacity
              onPress={openExploreLink}
              activeOpacity={0.9}
              style={tw`w-full mt-5 px-0 py-7`}
            >
              <Text style={tw`font-header text-black mb-3 text-lg`}>
                {locale.get('home.explore.title')}
              </Text>
              <Image
                source={
                  houseInfo?.explore_image_url
                    ? { uri: houseInfo.explore_image_url }
                    : require('../../../../assets/explore.jpg')
                }
                resizeMode="cover"
                style={tw`w-[100%] h-[300px]`}
              />
              <TouchableOpacity
                onPress={openExploreLink}
                activeOpacity={0.9}
                style={tw`bg-primary items-center p-3 mt-5`}
              >
                <Text style={tw`font-header text-white text-lg`}>
                  {locale.get('home.explore.button')}
                </Text>
              </TouchableOpacity>
            </TouchableOpacity>
          </InfoBlockWrapper>
        )}
      </Layout>
    </Animatable.View>
  );
};
