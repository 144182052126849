import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { View } from 'react-native';
import { DateData } from 'react-native-calendars';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  CalendarDataOutlet,
  DateStatuses,
  DateTypes,
  GetUserByIdQuery,
  LimitStatus,
  MarkedDates,
  PeakSeasonFragment,
  SelectedDates,
  SpecialDateFragment,
  StayFragment,
  Stay_Types_Enum,
  useCreateNewStayActionMutation,
  useUpdateStayByIdActionMutation,
} from '@hamlet/graphql-urql';
import {
  CalendarTypes,
  convertErrorMessage,
  convertMarkedData,
  convertUnavailableData,
  CURRENT_DATE,
  ERROR_MESSAGES,
  getActiveSelectedDates,
  getActiveStayLimits,
  getEndDateValidationError,
  getMarkedData,
  getSeasonType,
  getSelectedStatus,
  getStartDateValidationError,
  getStayRules,
  getStayValidationError,
} from '@hamlet/utils';
import dayjs from 'dayjs';

import * as Animatable from 'react-native-animatable';
import { useHaptic } from '../../../common/hooks/useHaptic';
import { useToast } from '../../../common/hooks/useToast';
import { RootRoutes } from '../../../common/routes/routes';
import { RootParamList } from '../../../common/routes/types';
import { tw } from '../../../common/styles/tailwind';
import { StayTabs } from '../../stay/types';
import { CalendarHeader } from '../components/CalendarHeader';
import { DEFAULT_SELECTED, DEFAULT_SELECTED_VALUE } from '../constants';
import { getButtonsStatus, getCalendarDayStyle, getBookingDetails } from '../utils';
import { BottomButton } from './BottomButton';
import { CalendarDay } from './CalendarDay';
import { TopButton } from './TopButton';
import { CustomCalendarList } from './CustomCalendarList';
import { isWeb } from '../../../common/constants';
import { ScreenLoader } from '../../../common/components/ScreenLoader';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';

interface Props {
  stays: StayFragment[];
  peakSeasons: PeakSeasonFragment[];
  userInfo: GetUserByIdQuery['users_by_pk'];
  calendarData: CalendarDataOutlet;
  allHouseSharesSold: boolean;
  specialDates: SpecialDateFragment[];
  calendarType: CalendarTypes;
  activeStayId?: string;
  limitStatus: LimitStatus | null;
  maxStayLength: number;
  maxGeneralStays: number;
  shares: number;
  refreshCalendarData: () => Promise<void>;
  toggleCalendarInfoModal: () => void;
}

const fadeIn = {
  from: {
    opacity: 0.5,
  },
  to: {
    opacity: 1,
  },
};

enum BG_COLOR {
  PRIMARY = '#478429',
}

export const Calendar: React.FC<Props> = ({
  stays,
  peakSeasons = [],
  userInfo,
  calendarData,
  allHouseSharesSold,
  specialDates,
  calendarType,
  activeStayId,
  limitStatus,
  maxStayLength,
  maxGeneralStays,
  shares,
  refreshCalendarData,
  toggleCalendarInfoModal,
}) => {
  const toast = useToast();
  const { locale } = useLocaleContext();
  const haptic = useHaptic();
  const { navigate, goBack } = useNavigation<StackNavigationProp<RootParamList>>();
  const [, createNewStayAction] = useCreateNewStayActionMutation();
  const [, updateStayByIdAction] = useUpdateStayByIdActionMutation();

  const [stayType, setStayType] = useState<Stay_Types_Enum>(Stay_Types_Enum.AdvanceStay);
  const [selected, setSelected] = useState<SelectedDates>(DEFAULT_SELECTED);
  const [selectedDates, setSelectedDates] = useState<{ [key: string]: MarkedDates }>({});
  const [isLoading, setIsLoading] = useState(false);

  const activeStayDuration = dayjs(selected.end.date).diff(selected.start.date, 'days');

  const markedDates = useMemo(
    () => convertMarkedData({ data: calendarData?.markedData || [], stays, activeStayId }),
    [calendarData?.markedData, stays, activeStayId]
  );

  const unavailableDates = useMemo(() => {
    return convertUnavailableData(calendarData?.unavailableDates || [], activeStayId);
  }, [calendarData?.unavailableDates, activeStayId]);

  const activeMarkedData = useMemo(() => {
    return getActiveSelectedDates({
      stays,
      markedData: calendarData.markedData || [],
      activeStayId,
    });
  }, [activeStayId, calendarData.markedData, stays]);

  const activeStayLimits = useMemo(() => {
    return getActiveStayLimits({ activeStayId: activeStayId || '', stays, specialDates });
  }, [activeStayId, specialDates, stays]);

  const bookingDetails = useMemo(() => {
    return getBookingDetails({
      stayType,
      selected,
      limitStatus,
      maxStayLength,
      stayDuration: activeStayDuration,
      activeStayLimits,
    });
  }, [stayType, selected, limitStatus, maxStayLength, activeStayDuration, activeStayLimits]);

  const { resetButtonVisible, bottomButtonVisible } = useMemo(
    () =>
      getButtonsStatus({
        selected,
        startDate: activeMarkedData?.start?.date || '',
        endDate: activeMarkedData?.end?.date || '',
      }),
    [activeMarkedData, selected]
  );

  const setActiveStayData = ({ start, end }: SelectedDates) => {
    const markedDatesData = getMarkedData({
      startData: start,
      endData: end,
      peakSeasons,
      specialDates,
    });
    setSelected({ start, end });
    setSelectedDates(markedDatesData);
  };

  const clearSelectedDates = () => {
    setSelected(DEFAULT_SELECTED);
    setSelectedDates({});
  };

  const resetSelectedDates = () => {
    if (activeMarkedData?.start && activeMarkedData?.end && calendarType === CalendarTypes.EDIT) {
      setActiveStayData({ start: activeMarkedData.start, end: activeMarkedData.end });
      return;
    }
    clearSelectedDates();
  };

  const updateCurrentStay = async () => {
    setIsLoading(true);
    if (!activeStayDuration || !userInfo?.house_id || !userInfo.id) {
      toast.danger(locale.get(ERROR_MESSAGES.default));
      setIsLoading(false);
      return;
    }

    const result = await updateStayByIdAction({
      data: {
        stay_id: activeStayId,
        house_id: userInfo.house_id,
        user_id: userInfo.id,
        selected,
        all_shares_sold: allHouseSharesSold,
      },
    });

    if (result.error || !result.data?.response.id) {
      const message = JSON.parse(convertErrorMessage(result.error?.message || ''));

      toast.danger(locale.get(message || ERROR_MESSAGES.default));
      await refreshCalendarData();
      setIsLoading(false);
      return;
    }

    await refreshCalendarData();
    clearSelectedDates();
    setIsLoading(false);
    goBack();
  };

  const handleCreateNewStay = async () => {
    setIsLoading(true);
    if (!userInfo?.id || !userInfo?.house_id) {
      toast.danger(locale.get(ERROR_MESSAGES.default));
      setIsLoading(false);
      return;
    }

    const newStay = await createNewStayAction({
      data: {
        selected,
        user_id: userInfo.id,
        house_id: userInfo.house_id,
        all_shares_sold: allHouseSharesSold,
      },
    });

    if (newStay.error || !newStay.data?.response.id) {
      const message = JSON.parse(convertErrorMessage(newStay.error?.message || ''));

      toast.danger(locale.get(message || ERROR_MESSAGES.default));
      await refreshCalendarData();
      setIsLoading(false);
      return;
    }

    await refreshCalendarData();
    clearSelectedDates();
    setIsLoading(false);
    navigate(RootRoutes.STAY_DETAILS, { id: newStay.data.response.id, type: StayTabs.UPCOMING });
  };

  const handleDayPress = (data: MarkedDates) => {
    haptic.success();
    const {
      onlyStartDateSelected,
      selectedBothDates,
      nothingSelected,
      incomingDateEqualStartDate,
      incomingDateBeforeStartDate,
    } = getSelectedStatus({ selected, incomingDate: data.date });

    const unavailableInfo = (calendarData?.unavailableDates || []).find(
      (el) => el.date === data.date && el.blockingStayId !== activeStayId
    );

    if (nothingSelected || selectedBothDates || incomingDateBeforeStartDate) {
      const validationStartDateError = getStartDateValidationError({
        incomingData: data,
        unavailableInfo,
        limitStatus,
        maxGeneralStays,
        shares,
        maxStayLength,
        allHouseSharesSold,
        activeStayLimits,
      });

      if (validationStartDateError) {
        toast.danger(locale.get(validationStartDateError));
        return;
      }

      setSelected({ start: data, end: DEFAULT_SELECTED_VALUE });
      setSelectedDates({
        [data.date]: {
          ...data,
          status: DateStatuses.Editable,
          startingStayDay: true,
          endingStayDay: false,
        },
      });
      return;
    }

    if (onlyStartDateSelected && incomingDateEqualStartDate) {
      clearSelectedDates();
      return;
    }

    const endDateValidationError = getEndDateValidationError({
      incomingDate: data,
      selected,
      unavailableInfo,
      limitStatus,
      maxStayLength,
      activeStayLimits,
    });

    if (endDateValidationError) {
      toast.danger(locale.get(endDateValidationError));
      return;
    }

    if (!onlyStartDateSelected) {
      return;
    }

    const rules = getStayRules({
      startData: selected.start,
      endData: data,
      specialDates,
      allHouseSharesSold,
      maxStayLength,
    });
    const stayValidationError = getStayValidationError({
      startData: selected.start,
      endData: data,
      rules,
      unavailableInfo,
      stays,
      specialDates,
      activeStayId,
      maxGeneralStays,
      maxStayLength,
      limitStatus,
      shares,
      allHouseSharesSold,
      type: calendarType,
      activeStayLimits,
    });

    if (stayValidationError) {
      toast.danger(locale.get(stayValidationError));
      return;
    }
    setStayType(rules.stayType);
    setSelected((prev) => ({ ...prev, end: data }));
    const markedDatesData = getMarkedData({
      startData: selected.start,
      endData: data,
      peakSeasons,
      specialDates,
    });

    setSelectedDates(markedDatesData);
  };

  const handleBottomButton =
    calendarType === CalendarTypes.EDIT ? updateCurrentStay : handleCreateNewStay;

  const getRenderItemStyles = useCallback(
    (date: string, marking: MarkedDates) => {
      const isCurrentDay = date === CURRENT_DATE;
      const unavailableDate = unavailableDates[date];

      const styles = getCalendarDayStyle({
        marking,
        isCurrentDay,
        unavailableDate,
        allHouseSharesSold,
      });

      return styles;
    },
    [selected, unavailableDates]
  );

  const renderDayItem = useCallback(
    ({ date: { dateString, day }, marking }: { date: DateData; marking: MarkedDates }) => {
      const freeDayMarking: MarkedDates = {
        date: dateString,
        dateType: DateTypes.RegularDate,
        seasonType: getSeasonType(peakSeasons, dateString),
        status: DateStatuses.Free,
        specialDateName: '',
        startingStayDay: true,
        endingStayDay: false,
        startingSpecialDay: false,
        endingSpecialDay: false,
      };
      const dayMarking = marking || freeDayMarking;

      const style = getRenderItemStyles(dateString, dayMarking);

      if (
        style.wrapper.backgroundColor !== undefined &&
        style.wrapper.backgroundColor === BG_COLOR.PRIMARY &&
        !dayMarking.startingStayDay
      ) {
        return (
          <Animatable.View
            style={style.container}
            useNativeDriver
            duration={400}
            animation={fadeIn}
          >
            <CalendarDay
              key={dateString}
              day={String(day)}
              style={style}
              data={dayMarking}
              onPress={handleDayPress}
            />
          </Animatable.View>
        );
      }
      return (
        <CalendarDay
          key={dateString}
          day={String(day)}
          style={style}
          data={dayMarking}
          onPress={handleDayPress}
        />
      );
    },
    [selected, calendarData]
  );

  useEffect(() => {
    if (
      activeMarkedData &&
      activeMarkedData?.start &&
      activeMarkedData?.end &&
      calendarType === CalendarTypes.EDIT &&
      !selected.start.date &&
      !selected.end.date
    ) {
      setActiveStayData({ start: activeMarkedData.start, end: activeMarkedData.end });
    }
  }, [activeMarkedData]);

  return (
    <>
      {isLoading && <ScreenLoader />}
      <Animatable.View
        useNativeDriver
        duration={1000}
        animation="fadeIn"
        style={tw`h-full w-full flex flex-1 items-center justify-center`}
      >
        <View
          style={isWeb ? tw`shadow-lg flex h-full max-w-web w-full` : tw`h-full flex-1 flex w-full`}
        >
          <CalendarHeader />
          <TopButton
            title={locale.get('book.reset')}
            isVisible={resetButtonVisible}
            handlePress={resetSelectedDates}
          />
          <CustomCalendarList
            dayComponent={renderDayItem as any}
            markedDates={{ ...markedDates, ...selectedDates } as any}
          />
        </View>
      </Animatable.View>
      <BottomButton
        calendarType={calendarType}
        stayDuration={activeStayDuration}
        selected={selected}
        bookingDetails={bookingDetails}
        stayType={stayType}
        toggleCalendarInfoModal={toggleCalendarInfoModal}
        isVisible={bottomButtonVisible}
        handlePress={handleBottomButton}
        handleReset={resetSelectedDates}
      />
    </>
  );
};
