import React, { useEffect, useState } from 'react';
import { TouchableOpacity, Text, View, LayoutChangeEvent, Pressable } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  interpolateColor,
  interpolate,
  withTiming,
  useDerivedValue,
  cancelAnimation,
} from 'react-native-reanimated';
import { CalendarTypes } from '@hamlet/utils';
import { SelectedDates, Stay_Types_Enum } from '@hamlet/graphql-urql';

import { tw } from '../../../common/styles/tailwind';
import { useHaptic } from '../../../common/hooks/useHaptic';
import { CustomModal } from '../../../common/components/CustomModal';
import { PolicyInfo } from './PolicyInfo';
import { DatesInfo } from './DatesInfo';
import { PolicyInfoTypes } from '../types';
import { BOTTOM_BUTTON_TITLE, MODAL_WINDOW_DATA, POLICY_TITLES } from '../constants';
import { isWeb } from '../../../common/constants';
import HelpButton from '../../../common/components/HelpButton';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';

interface Props {
  calendarType: CalendarTypes;
  selected: SelectedDates;
  isVisible: boolean;
  stayDuration: number;
  stayType: Stay_Types_Enum;
  bookingDetails: {
    title: string;
    description: { path: string; options: { [key: string]: any } };
  };
  handlePress: () => void;
  handleReset: () => void;
  toggleCalendarInfoModal: () => void;
}

const OFFSET_VALUE = 90;

export const BottomButton: React.FC<Props> = ({
  calendarType,
  selected,
  isVisible,
  stayDuration,
  stayType,
  bookingDetails,
  handlePress,
  handleReset,
  toggleCalendarInfoModal,
}) => {
  const haptic = useHaptic();
  const { locale } = useLocaleContext();
  const [height, setHeight] = useState(0);
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState<PolicyInfoTypes>('cancelation');

  const offset = useSharedValue({ bottom: -OFFSET_VALUE });
  const derivedOffset = useDerivedValue(() => withTiming(offset.value.bottom));

  const modalData = MODAL_WINDOW_DATA[modalType];
  const stayDays = stayDuration + 1 || 0;
  const stayNights = stayDuration || 0;

  const showModal = (type: PolicyInfoTypes) => {
    setModalType(type);
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const buttonStyles = useAnimatedStyle(() => {
    const backgroundColor = interpolateColor(
      derivedOffset.value,
      [0, height],
      ['#478429', '#75A45E']
    );

    return { backgroundColor };
  });

  const animatedStyles = useAnimatedStyle(() => ({
    bottom: withSpring(offset.value.bottom, { damping: 17 }),
  }));

  const animatedBackDropStyles = useAnimatedStyle(() => {
    const zIndex = interpolate(offset.value.bottom, [-OFFSET_VALUE, 0, height], [-1, -1, 1]);
    const opacity = interpolate(derivedOffset.value, [-OFFSET_VALUE, 0, height], [0, 0, 0.3]);

    return { zIndex, opacity };
  });

  const handleTopButtonPress = () => {
    cancelAnimation(offset);
    haptic.success();
    offset.value = { bottom: offset.value.bottom > 0 ? 0 : height };
  };

  const handleConfirmStay = () => {
    cancelAnimation(offset);
    haptic.success();
    offset.value = { bottom: 0 };
    setTimeout(handlePress, 500);
  };

  const onLayoutChange = (event: LayoutChangeEvent) => {
    const bodyHeight = event.nativeEvent.layout.height - 1; // -1 because nativeEvent.layout returns a rounded up values

    if (bodyHeight !== height) {
      setHeight(bodyHeight);
    }
  };

  useEffect(() => {
    cancelAnimation(offset);
    offset.value = { bottom: isVisible ? 0 : -OFFSET_VALUE };
  }, [isVisible]);

  return (
    <>
      <CustomModal
        visible={visible}
        handleLeftButton={hideModal}
        leftButtonText="OK"
        title={locale.get(modalData.title)}
        description={locale.get(modalData.description)}
      />
      <Animated.View style={[tw`w-full h-full absolute bg-black`, animatedBackDropStyles]}>
        <Pressable style={tw`w-full h-full`} onPress={handleTopButtonPress} />
      </Animated.View>
      <Animated.View style={[tw`w-full absolute items-center z-10 `, animatedStyles]}>
        {isWeb && <HelpButton containerStyle="bottom-28" onPress={toggleCalendarInfoModal} />}
        <Animated.View style={[tw`w-full items-center rounded`, buttonStyles]}>
          <TouchableOpacity
            onPress={handleTopButtonPress}
            style={tw`w-full items-center justify-center h-[${OFFSET_VALUE}px]`}
            activeOpacity={0.8}
          >
            <Text style={tw`font-medium font-header text-base text-white mb-2`}>
              {locale.get(BOTTOM_BUTTON_TITLE[calendarType])}
            </Text>
            <Text style={tw`font-medium font-header text-base text-white`}>
              {locale.get('book.button_description', {
                nights: String(stayNights),
                days: String(stayDays),
              })}
            </Text>
          </TouchableOpacity>
        </Animated.View>
        <View
          style={tw`w-full absolute top-[${OFFSET_VALUE}px] items-center z-10 p-4 bg-white`}
          onLayout={onLayoutChange}
        >
          <DatesInfo selected={selected} />
          <PolicyInfo
            onPress={showModal}
            type={stayType}
            title={locale.get(bookingDetails.title)}
            description={locale.get(
              bookingDetails.description.path,
              bookingDetails.description.options
            )}
            selected={selected}
          />
          <PolicyInfo
            onPress={showModal}
            type="cancelation"
            title={locale.get(POLICY_TITLES.cancelation)}
            description={locale.get('policy_description.cancelation')}
            selected={selected}
          />
          <View style={tw`w-full max-w-3xl`}>
            <TouchableOpacity
              onPress={handleConfirmStay}
              activeOpacity={0.8}
              style={tw`bg-primary py-4`}
            >
              <Text style={tw`text-white font-bold font-header self-center`}>
                {locale.get('book.confirm_button')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={handleReset} activeOpacity={0.8}>
              <Text style={tw`text-center font-header text-red-600 font-bold py-4`}>
                {locale.get('book.reset_button')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Animated.View>
    </>
  );
};
