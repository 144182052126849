import React from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as WebBrowser from 'expo-web-browser';

import { Header } from '../../../common/components/Header';
import { ButtonType } from '../../../common/components/HeaderLeftButton';
import { tw } from '../../../common/styles/tailwind';
import { SettingsItem } from '../components/SettingsItem';
import { ProfileIconNames } from '../types';
import { Layout } from '../../../common/components/Layout';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';

export const AboutApp: React.FC = () => {
  const { locale } = useLocaleContext();

  const openWebLink = async (link: string) => {
    try {
      await WebBrowser.openBrowserAsync(link);
    } catch (error) {}
  };

  return (
    <SafeAreaView style={tw`w-full h-full bg-white`} edges={['top']}>
      <Header
        leftButtonType={ButtonType.BACK}
        title={locale.get('about.header_title')}
        showRightIcon
        rightIcon={<View style={tw`w-7`} />}
      />
      <Layout scrollable webStyles="shadow-lg">
        <SettingsItem
          title={locale.get('about.policy_title')}
          iconName={ProfileIconNames.PRIVACY}
          onPress={() =>
            openWebLink(
              'https://docs.google.com/document/d/1avzY-PZjU9dIQ_U79sNVZJS56VDF2fIBPFKxGZSD20g/edit?usp=sharing'
            )
          }
        />
        <SettingsItem
          title={locale.get('about.terms_title')}
          iconName={ProfileIconNames.TERMS}
          onPress={() =>
            openWebLink(
              'https://docs.google.com/document/d/1LR91p3ztoh_RVIoQSZNRtf26qujfTgtK3egLqdCj9ug/edit?usp=sharing'
            )
          }
        />
      </Layout>
    </SafeAreaView>
  );
};
