import React from 'react';
import { SafeAreaView, Text, TouchableOpacity } from 'react-native';

import { tw } from '../../../styles/tailwind';
import { HEADER_BUTTON_HIT_SLOP } from '../constants';

interface Props {
  onRequestClose: () => void;
}

export const ImageDefaultHeader = ({ onRequestClose }: Props) => (
  <SafeAreaView style={tw`items-end`}>
    <TouchableOpacity
      style={tw`mr-2 mt-2 w-11 h-11 items-center justify-center rounded-3xl bg-[#00000077]`}
      onPress={onRequestClose}
      hitSlop={HEADER_BUTTON_HIT_SLOP}
    >
      <Text style={tw`leading-6 text-xl text-center text-white`}>✕</Text>
    </TouchableOpacity>
  </SafeAreaView>
);
