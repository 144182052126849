import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import Fontisto from 'react-native-vector-icons/Fontisto';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { CustomBottomSheetModal } from '../../../common/components/CustomBottomSheetModal';
import { Layout } from '../../../common/components/Layout';
import { ModalSectionElement } from '../../../common/components/ModalSectionElement';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';
import { tw } from '../../../common/styles/tailwind';
import { YourBookingsExample, SpecialDateExample, PeakSeasonExample } from './BookingsExamples';

const CalendarHelpModal = ({
  isInfoModalVisible,
  closeModal,
  openStayRules,
}: {
  isInfoModalVisible: boolean;
  closeModal: (value: boolean) => void;
  openStayRules: () => void;
}) => {
  const { locale } = useLocaleContext();

  return (
    <CustomBottomSheetModal
      closeModal={() => closeModal(false)}
      isVisible={isInfoModalVisible}
      snapPoints={['50%', '95%']}
      title={locale.get('modals.help.title')}
    >
      <Layout
        scrollable
        contentContainerStyle={tw`pb-[30px]`}
        commonStyles="px-4"
        webStyles="mt-10"
      >
        <ModalSectionElement
          icon={<Fontisto size={26} name="date" color="black" />}
          title={locale.get('modals.help.booked_title')}
          description={locale.get('modals.help.booked_description')}
          example={<YourBookingsExample />}
        />
        <ModalSectionElement
          icon={<MaterialCommunityIcons size={26} name="progress-star" color="black" />}
          title={locale.get('modals.help.special_title')}
          description={locale.get('modals.help.special_description')}
          example={<SpecialDateExample />}
        />
        <ModalSectionElement
          icon={<MaterialCommunityIcons size={26} name="chart-bell-curve" color="black" />}
          title={locale.get('modals.help.peak_title')}
          description={locale.get('modals.help.peak_description')}
          example={<PeakSeasonExample />}
        />
        <ModalSectionElement
          icon={<MaterialCommunityIcons size={26} name="calendar-clock-outline" color="black" />}
          title={locale.get('modals.help.short_title')}
          description={locale.get('modals.help.short_description')}
        />
        <TouchableOpacity
          onPress={openStayRules}
          style={tw`flex items-center bg-primary rounded-md mt-5`}
          activeOpacity={0.8}
        >
          <Text style={tw`text-lg font-bold text-white p-2 font-header`}>
            {locale.get('modals.help.bottom_button')}
          </Text>
        </TouchableOpacity>
      </Layout>
    </CustomBottomSheetModal>
  );
};
export default CalendarHelpModal;
