import React from 'react';
import { Route, TabBar, TabBarProps } from 'react-native-tab-view';

import { tw } from '../../../common/styles/tailwind';

export const CustomTabBar: React.FC<TabBarProps<Route>> = (props) => (
  <TabBar
    {...props}
    style={tw`mx-5 bg-white my-5 border-1 h-12 border-primaryBlack rounded`}
    labelStyle={tw`font-header`}
    activeColor="white"
    indicatorStyle={tw`bg-primary h-12 left-[-1px] top-[-1px] rounded border-b-primary`}
    inactiveColor="#444443"
  />
);
