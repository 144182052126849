import React from 'react';
import { FlatList, TouchableOpacity, View, Text } from 'react-native';
import { Route } from 'react-native-tab-view';
import { LimitStatus, StayFragment } from '@hamlet/graphql-urql';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Stay_Statuses_Enum } from '@hamlet/graphql-urql';
import { formatDate, getDifferenceBetween } from '@hamlet/utils';

import { ListHeader } from './ListHeader';
import { RootRoutes } from '../../../common/routes/routes';
import { tw } from '../../../common/styles/tailwind';
import { RootParamList } from '../../../common/routes/types';
import { ListFooter } from './ListFooter';
import { ChevronRightIcon } from '../../../common/components/icons/ChevronRightIcon';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';

interface Props {
  data: StayFragment[];
  route: Route;
  userLimits: LimitStatus | null;
}

export const TabViewList: React.FC<Props> = ({ data, route, userLimits }) => {
  const { locale } = useLocaleContext();
  const keyExtractor = (item: any) => `stay-${item.id}`;

  const { navigate } = useNavigation<StackNavigationProp<RootParamList>>();

  const renderItem = ({ item }: { item: StayFragment; index: number }) => {
    const arriveDay = formatDate(item.start_date, 'DD', locale.language);
    const arriveYear = formatDate(item.start_date, 'YYYY', locale.language);
    const arriveMonth = formatDate(item.start_date, 'MMM', locale.language);
    const difference = getDifferenceBetween(item.end_date, item.start_date, 'days');

    const handlePress = () => {
      navigate(RootRoutes.STAY_DETAILS, { id: item.id, type: route.key });
    };

    return (
      <TouchableOpacity
        onPress={handlePress}
        style={tw`flex-row mx-5 items-center border-b-1 pr-2.5 border-gray-300 justify-between`}
        activeOpacity={0.8}
      >
        <View style={tw`flex-row items-center`}>
          <View style={tw`bg-thrid p-2.5 w-20`}>
            <Text style={tw`text-2xl font-base text-center text-primaryBlack`}>{arriveDay}</Text>
            <Text style={tw`text-sm font-header text-center text-primaryBlack uppercase`}>
              {arriveMonth}
            </Text>
            <Text style={tw`text-sm font-header text-center text-primaryBlack`}>{arriveYear}</Text>
          </View>
          <View style={tw`pl-4 max-w-[73%] overflow-hidden`}>
            <Text style={tw`font-header text-base text-primaryBlack uppercase`}>
              {locale.get(`stays.item.${item.type}`)}
            </Text>
            <Text style={tw`text-sm text-lightBlack font-header`}>
              {locale.get('stays.item.description', {
                nights: String(difference),
              })}
            </Text>
            {item.status !== Stay_Statuses_Enum.Booked && (
              <Text style={tw`font-xs font-bold font-header`}>
                {item.status === Stay_Statuses_Enum.Canceled
                  ? locale.get('stays.canceled_tab')
                  : locale.get('stays.refunded_tab')}
              </Text>
            )}
          </View>
        </View>
        <ChevronRightIcon style={tw`text-primary`} />
      </TouchableOpacity>
    );
  };

  const listHeader = () => <ListHeader type={route.key} />;
  const listFooter = () => <ListFooter routeKey={route.key} userLimits={userLimits} />;

  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ListHeaderComponent={listHeader}
      ListFooterComponent={listFooter}
      contentContainerStyle={tw`mb-24`}
    />
  );
};
