import React from 'react';
import { View, Text } from 'react-native';
import { LimitStatus } from '@hamlet/graphql-urql';

import { StayTabs } from '../types';
import { tw } from '../../../common/styles/tailwind';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';

interface Props {
  routeKey: string;
  userLimits: LimitStatus | null;
}

export const ListFooter: React.FC<Props> = ({ routeKey, userLimits }) => {
  const { locale } = useLocaleContext();

  if (routeKey !== StayTabs.UPCOMING || !userLimits) {
    return null;
  }

  const description = locale.get('stays.limits', {
    stays: `${userLimits.advanceStaysBooked}/${userLimits.advanceStaysLimit}`,
    special: `${userLimits.specialDatesBooked}/${userLimits.specialDatesLimit}`,
  });

  return (
    <View
      style={tw`flex-row px-4 items-center mx-5 py-4 border-b-1 border-gray-300 mb-4 justify-between`}
    >
      <View>
        <Text style={tw`text-base font-header text-lightBlack uppercase`}>
          {locale.get('stays.limits_title')}
        </Text>
        <View style={tw`flex flex-row text-sm text-primaryBlack font-base`}>
          <Text>{description}</Text>
          {/* <Text> · </Text>
          <Text>
            {userLimits.daysBooked}/{userLimits.daysLimit} Days
          </Text> */}
        </View>
      </View>
    </View>
  );
};
