import React, { PropsWithChildren } from 'react';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import {
  createClient,
  Provider,
  dedupExchange,
  cacheExchange,
  fetchExchange,
  errorExchange,
} from 'urql';
import { makeOperation } from '@urql/core';
import { authExchange } from '@urql/exchange-auth';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { TokenResponseConfig } from 'expo-auth-session';

import appConfig from '../config/appConfig';
import { isWeb } from '../constants';
import { getActualTokenData } from '../hooks/auth0';
import { useUserState } from '../state';

const getHasuraUrl = () => {
  let url = appConfig.hasuraUri;

  if (Platform.OS === 'android') {
    const IP = Constants.manifest?.debuggerHost?.split(`:`).shift();

    if (IP) {
      url = url.replace('localhost', IP);
    }
  }

  return url;
};

const getAuthExchange = () => {
  const { getItem: getCachedToken } = useAsyncStorage('jwtToken');

  return authExchange({
    addAuthToOperation: ({
      authState,
      operation,
    }: {
      authState: { token: string } | null;
      operation: any;
    }) => {
      if (!authState || !authState?.token) {
        return operation;
      }

      const fetchOptions =
        typeof operation.context.fetchOptions === 'function'
          ? operation.context.fetchOptions()
          : operation.context.fetchOptions || {};

      return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            Authorization: `Bearer ${authState.token}`,
          },
        },
      });
    },
    willAuthError: ({ authState }) => !authState,
    didAuthError: ({ error }) => {
      return error.graphQLErrors.some((e) => e.extensions?.code === 'FORBIDDEN');
    },
    getAuth: async ({ authState }) => {
      if (authState) {
        return null;
      }

      const tokenString = await getCachedToken();

      if (!tokenString) {
        return null;
      }

      const oldTokenConfig: TokenResponseConfig = JSON.parse(tokenString);

      if (!oldTokenConfig?.accessToken) {
        return null;
      }

      return { token: oldTokenConfig.accessToken };
    },
  });
};

const getErrorExchange = () => {
  const { deleteUserInfo, setAccessToken } = useUserState();
  const { getItem: getCachedToken, setItem: setToken } = useAsyncStorage('jwtToken');
  const { setItem: setLanguage } = useAsyncStorage('language');

  const logout = async () => {
    await setToken('');
    await setLanguage('');
    deleteUserInfo();
  };

  return errorExchange({
    onError: async (error) => {
      const isAuthError = error.graphQLErrors.some((e) => e.extensions?.code === 'invalid-jwt');

      if (!isAuthError) {
        return;
      }

      if (isWeb) {
        await logout();
        return;
      }

      const tokenString = await getCachedToken();

      if (!tokenString) {
        await logout();
        return;
      }

      const oldTokenConfig: TokenResponseConfig = JSON.parse(tokenString);

      if (!oldTokenConfig) {
        await logout();
        return;
      }

      const { tokenConfig, accessToken } = await getActualTokenData(oldTokenConfig);

      if (!accessToken) {
        await logout();
        return;
      }

      setToken(tokenConfig);
      setAccessToken(accessToken);
    },
  });
};

export const UrqlProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const url = getHasuraUrl();

  return (
    <Provider
      value={createClient({
        url,
        exchanges: [
          dedupExchange,
          cacheExchange,
          getAuthExchange(),
          getErrorExchange(),
          fetchExchange,
        ],
      })}
    >
      {children}
    </Provider>
  );
};
