import React from 'react';
import { ActivityIndicator, View } from 'react-native';

import { tw } from '../../../../styles/tailwind';
import { SCREEN } from '../../constants';

export const ImageLoading = () => (
  <View style={[tw`items-center justify-center`, { width: SCREEN.width, height: SCREEN.height }]}>
    <ActivityIndicator size="small" color="#FFF" />
  </View>
);
