import React from 'react';
import { ActivityIndicator } from 'react-native';
import { ActivityIndicator as PaperActivityIndicator } from 'react-native-paper';

import { isWeb } from '../constants';

interface Props {
  color?: string;
  size?: 'small' | 'large';
}

export const LoadingIndicator: React.FC<Props> = ({ color = '#478429', size = 'large' }) => {
  if (isWeb) {
    return <ActivityIndicator color={color} size={size} />;
  }

  return <PaperActivityIndicator color={color} size={size} />;
};
