import React from 'react';
import { Text, View } from 'react-native';
import { formatDate } from '@hamlet/utils';

import { tw } from '../../../common/styles/tailwind';
import { Languages } from '../../../common/hooks/locale';
interface Props {
  current: string;
  locale: Languages;
}

export const MonthHeader: React.FC<Props> = ({ current, locale }) => {
  const month = formatDate(current, 'MMMM YYYY', locale);

  return (
    <View style={tw`px-4 pb-6 flex-row w-full justify-between items-center items-center`}>
      <Text style={tw`text-lg font-header uppercase`}>{month}</Text>
    </View>
  );
};
