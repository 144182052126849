import React from 'react';
import { ERROR_MESSAGES, formatDate } from '@hamlet/utils';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import { Image, Platform, Text, View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { SafeAreaView } from 'react-native-safe-area-context';

import { Header } from '../../../common/components/Header';
import { Layout } from '../../../common/components/Layout';
import { isWeb } from '../../../common/constants';
import { useAuth0 } from '../../../common/hooks/auth0';
import { useToast } from '../../../common/hooks/useToast';
import { ProfileRoutes, RootRoutes, RootTabRoutes } from '../../../common/routes/routes';
import { ProfileStackParamList, RootStackParamList } from '../../../common/routes/types';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';
import { useUserState } from '../../../common/state';
import { tw } from '../../../common/styles/tailwind';
import { SettingsItem } from '../components/SettingsItem';
import { ProfileIconNames, UserFieldNames } from '../types';
import { Languages } from '../../../common/hooks/locale';

WebBrowser.maybeCompleteAuthSession();

export const ProfileScreen = () => {
  const toast = useToast();
  const { locale } = useLocaleContext();
  const { logout } = useAuth0();
  const { reset, navigate } =
    useNavigation<StackNavigationProp<RootStackParamList & ProfileStackParamList>>();
  const { userInfo, deleteUserInfo } = useUserState();

  const { setItem: setToken } = useAsyncStorage('jwtToken');

  const yearOfAccountCreation = formatDate(userInfo?.created_at, "'YY");

  const handleLogout = async () => {
    const res = await logout();

    if (res.type !== 'success') {
      toast.danger(locale.get(ERROR_MESSAGES.default));
      return;
    }

    await locale.changeLanguage(Languages.EN);
    await setToken('');
    reset({ index: 0, routes: [{ name: RootRoutes.LOGIN }] });
    deleteUserInfo();
  };

  const handleContactInformation = () => navigate(ProfileRoutes.CONTACT_INFORMATION);
  const handleSettings = () =>
    navigate(ProfileRoutes.EDIT_INFORMATION, { name: UserFieldNames.SETTINGS });

  const handleAbout = () => navigate(ProfileRoutes.ABOUT_APP);

  const getBuildNumber = () => {
    if (Platform.OS === 'ios') {
      return Constants?.expoConfig?.ios?.buildNumber;
    }
    return Constants?.expoConfig?.android?.versionCode;
  };

  return (
    <Animatable.View
      useNativeDriver
      duration={1000}
      animation="fadeIn"
      style={tw`h-full w-full flex flex-1 items-center justify-center`}
    >
      <SafeAreaView style={tw`w-full h-full bg-white`} edges={['top']}>
        <Header
          title={locale.get('profile.header_title')}
          showNavigationButtons={isWeb}
          activeButtonRoute={RootTabRoutes.PROFILE_ROOT}
          showAllButtons={userInfo?.house_id && userInfo?.house_share}
        />
        <Layout scrollable webStyles="shadow-lg">
          <View style={tw`items-center my-5 mx-4`}>
            {userInfo?.picture && (
              <Image
                source={{ uri: userInfo?.picture }}
                style={tw`w-18 h-18 mb-2.5 rounded-full`}
              />
            )}
            <Text style={tw`text-2xl font-base`}>{userInfo?.given_name}</Text>
            <Text style={tw`mt-1.5 text-sm text-gray-600 font-header`}>
              {locale.get('profile.owner_since', { year: yearOfAccountCreation })}
            </Text>
          </View>
          <View>
            <SettingsItem
              title={locale.get('profile.contact_title')}
              description={locale.get('profile.contact_description')}
              iconName={ProfileIconNames.CHEVRON_RIGHT}
              onPress={handleContactInformation}
            />
            <SettingsItem
              title={locale.get('profile.settings_title')}
              description={locale.get('profile.settings_description')}
              iconName={ProfileIconNames.CHEVRON_RIGHT}
              onPress={handleSettings}
            />
            <SettingsItem
              title={locale.get('profile.about_title')}
              description={locale.get('profile.about_description')}
              iconName={ProfileIconNames.CHEVRON_RIGHT}
              onPress={handleAbout}
            />
            <SettingsItem
              title={locale.get('profile.sign_out')}
              iconName={ProfileIconNames.LOGOUT}
              onPress={handleLogout}
            />
          </View>
          {!isWeb && (
            <View style={tw`w-full items-center mt-10`}>
              <Text style={tw`text-gray-400`}>
                {locale.get('profile.version', { version: getBuildNumber() as string })}
              </Text>
            </View>
          )}
        </Layout>
      </SafeAreaView>
    </Animatable.View>
  );
};
