import React from 'react';
import { Pressable, Text } from 'react-native';
import classNames from 'classnames';

import { Languages } from '../../../common/hooks/locale';
import { tw } from '../../../common/styles/tailwind';

interface Props {
  active: boolean;
  type: Languages;
  onPress: (type: Languages) => void;
}

export const LanguageButton: React.FC<Props> = ({ active, type, onPress }) => {
  const containerStyles = classNames(
    'px-3 mx-2 py-2 rounded-lg border-[1px]',
    active ? 'border-primary' : 'border-gray-200'
  );

  const textStyles = classNames('font-header uppercase', active ? 'text-primary' : 'text-gray-200');

  const handlePress = () => onPress(type);

  return (
    <Pressable style={tw`${containerStyles}`} onPress={handlePress}>
      <Text style={tw`${textStyles}`}>{type}</Text>
    </Pressable>
  );
};
