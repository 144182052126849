import React, { useState } from 'react';
import { Platform } from 'react-native';
import { RouteProp, useRoute } from '@react-navigation/native';
import { Stay_Statuses_Enum } from '@hamlet/graphql-urql';
import { CalendarTypes } from '@hamlet/utils';
import { Edge, SafeAreaView } from 'react-native-safe-area-context';
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';

import { RootRoutes } from '../../../common/routes/routes';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';
import { RootParamList } from '../../../common/routes/types';
import { Calendar } from '../components/Calendar';
import { useUserState } from '../../../common/state';
import { ScreenLoader } from '../../../common/components/ScreenLoader';
import { useCalendarData } from '../../../common/hooks/useCalendarData';
import { Header } from '../../../common/components/Header';
import { ButtonType } from '../../../common/components/HeaderLeftButton';
import { tw } from '../../../common/styles/tailwind';
import CalendarHelpModal from '../components/CalendarHelpModal';
import BookingRulesModal from '../components/BookingRulesModal';

const edges: Edge[] = Platform.select({ android: ['top', 'bottom'], default: ['bottom'] });

export const StayModifyScreen = () => {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [isRuleModalVisible, setIsRuleModalVisible] = useState(false);
  const { params } = useRoute<RouteProp<RootParamList, RootRoutes.MODIFY_MY_STAY>>();
  const { locale } = useLocaleContext();
  const { userInfo, houseInfo, calendarData, userLimits } = useUserState();
  const allHouseSharesSold = houseInfo?.shares_total === houseInfo?.sold_shares;
  const houseShare = userInfo?.house_share;

  if (!userInfo || !houseShare) {
    return <ScreenLoader />;
  }

  const { refreshCalendarData } = useCalendarData();
  const stays = React.useMemo(
    () => (houseInfo?.stays || []).filter((stay) => stay.status === Stay_Statuses_Enum.Booked),
    [houseInfo?.stays]
  );

  const toggleCalendarInfoModal = () => {
    setIsInfoModalVisible((prev) => !prev);
  };

  const openStayRules = () => {
    setIsInfoModalVisible(false);
    setIsRuleModalVisible((prev) => !prev);
  };

  return (
    <SafeAreaView style={tw`w-full h-full`} edges={edges}>
      <Header
        title={locale.get('stay_modify.header_title')}
        rightIcon={<SimpleLineIcons name="question" color="black" size={26} />}
        onPressRightIcon={toggleCalendarInfoModal}
        leftButtonType={ButtonType.CLOSE}
      />
      <CalendarHelpModal
        isInfoModalVisible={isInfoModalVisible}
        closeModal={(value) => setIsInfoModalVisible(value)}
        openStayRules={openStayRules}
      />
      <BookingRulesModal
        isVisible={isRuleModalVisible}
        closeModal={() => setIsRuleModalVisible(false)}
      />
      <Calendar
        stays={stays}
        userInfo={userInfo}
        calendarData={calendarData}
        allHouseSharesSold={allHouseSharesSold}
        toggleCalendarInfoModal={toggleCalendarInfoModal}
        refreshCalendarData={refreshCalendarData}
        specialDates={houseInfo?.special_dates || []}
        peakSeasons={houseInfo?.peak_seasons || []}
        calendarType={CalendarTypes.EDIT}
        activeStayId={params.id}
        limitStatus={userLimits}
        maxGeneralStays={houseShare.general_stays}
        maxStayLength={houseShare.max_stay_length}
        shares={houseShare.shares}
      />
    </SafeAreaView>
  );
};
