import React, { useMemo, useState } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  Stay_Statuses_Enum,
  Stay_Types_Enum,
  useUpdateStayByIdMutation,
} from '@hamlet/graphql-urql';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
  formatDate,
  SUCCESS_MESSAGES,
  DAYS_WITHOUT_PENALTY,
  CURRENT_DATE,
  ONLY_DAY_FORMAT,
  MONTH_YEAR_FORMAT,
} from '@hamlet/utils';
import dayjs from 'dayjs';
import * as Animatable from 'react-native-animatable';
import { LinearGradient } from 'expo-linear-gradient';

import { RootParamList } from '../../../common/routes/types';
import { useToast } from '../../../common/hooks/useToast';
import { RootRoutes } from '../../../common/routes/routes';
import { ScreenLoader } from '../../../common/components/ScreenLoader';
import { CustomModal } from '../../../common/components/CustomModal';
import { tw } from '../../../common/styles/tailwind';
import { useUserState } from '../../../common/state';
import { StayTabs } from '../types';
import { Header } from '../../../common/components/Header';
import { ButtonType } from '../../../common/components/HeaderLeftButton';
import { useCalendarData } from '../../../common/hooks/useCalendarData';
import { Layout } from '../../../common/components/Layout';
import { HorizontalLine } from '../../profile/components/HorizontalLine';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';
import { useHaptic } from '../../../common/hooks/useHaptic';
import { isWeb } from '../../../common/constants';

export const StayDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { locale } = useLocaleContext();
  const { params } = useRoute<RouteProp<RootParamList, RootRoutes.STAY_DETAILS>>();
  const { houseInfo } = useUserState((state) => state);
  const { navigate, goBack } = useNavigation<StackNavigationProp<RootParamList>>();
  const [, updateStayById] = useUpdateStayByIdMutation();
  const [visible, changeVisible] = useState(false);
  const upcomingStay = params.type === StayTabs.UPCOMING;
  const { refreshCalendarData } = useCalendarData();

  const stay = useMemo(() => {
    return (houseInfo?.stays || []).find((el) => el.id === params.id);
  }, [houseInfo]);

  if (!stay) {
    return <ScreenLoader />;
  }

  const arriveDate = formatDate(stay.start_date, MONTH_YEAR_FORMAT, locale.language);
  const arrivalDay = formatDate(stay.start_date, ONLY_DAY_FORMAT);
  const departDate = formatDate(stay.end_date, MONTH_YEAR_FORMAT, locale.language);
  const departDay = formatDate(stay.end_date, ONLY_DAY_FORMAT);
  const daysToStartDate = dayjs(stay.start_date).diff(CURRENT_DATE, 'days');
  const shortNoticeStay = stay.type === Stay_Types_Enum.ShortNoticeStay;
  const withoutPenalty = daysToStartDate >= DAYS_WITHOUT_PENALTY || shortNoticeStay;
  const description = withoutPenalty
    ? locale.get('stay_details.modal.description')
    : locale.get('stay_details.modal.penalty_description');
  const refundedStay = stay.status === Stay_Statuses_Enum.Refunded;

  const showModal = () => {
    useHaptic().success();
    changeVisible(true);
  };

  const hideModal = () => {
    changeVisible(false);
  };

  const handleCancelStay = async () => {
    setIsLoading(true);
    const status = withoutPenalty ? Stay_Statuses_Enum.Refunded : Stay_Statuses_Enum.Canceled;

    hideModal();
    await updateStayById({ id: params.id, stay: { status } });
    await refreshCalendarData();
    setIsLoading(false);
    goBack();
    toast.success(locale.get(SUCCESS_MESSAGES.cancelStay));
  };

  const handleModify = () => {
    navigate(RootRoutes.MODIFY_MY_STAY, { id: params.id });
  };

  return (
    <>
      <SafeAreaView style={tw`w-full bg-white`} edges={['top']}>
        {isLoading && <ScreenLoader />}
        <Header leftButtonType={ButtonType.BACK} />
        <Layout contentContainerStyle={tw`pb-[70%] bg-[#E5EFFF]`} scrollable webStyles="shadow-lg">
          <LinearGradient
            colors={['#FFF', '#E5EFFF']}
            style={{ position: 'absolute', height: '100%', width: '100%', bottom: 0, zIndex: 0 }}
          />
          <CustomModal
            visible={visible}
            handleLeftButton={hideModal}
            handleRightButton={handleCancelStay}
            leftButtonText={locale.get('stay_details.modal.left_button')}
            rightButtonText={locale.get('stay_details.modal.right_button')}
            rightButtonDanger
            title={locale.get('stay_details.modal.title')}
            description={description}
          />
          <View style={tw`p-4`}>
            <Animatable.Text
              useNativeDriver
              duration={900}
              delay={100}
              animation="fadeInDown"
              style={tw`font-base text-2xl`}
            >
              {dayjs().isBefore(dayjs(stay.start_date))
                ? locale.get('stay_details.future_header')
                : locale.get('stay_details.past_header')}
            </Animatable.Text>
            <Animatable.Text
              useNativeDriver
              duration={900}
              animation="fadeInDown"
              style={tw`font-base text-3xl`}
            >
              {houseInfo?.name}
            </Animatable.Text>
            <Animatable.View useNativeDriver duration={1000} delay={300} animation="fadeIn">
              <HorizontalLine style="mb-0.5" />
              <HorizontalLine style="mb-10" />
            </Animatable.View>
            <Animatable.View
              useNativeDriver
              duration={1500}
              delay={300}
              animation="fadeIn"
              style={tw`mb-6 w-full items-center`}
            >
              <Text style={tw`font-signature p-5 text-8xl`}>
                {locale.get('stay_details.your_stay')}
              </Text>
            </Animatable.View>
            <View style={tw`flex items-center w-full`}>
              <View style={tw`flex-col w-[60%]`}>
                <Animatable.View
                  useNativeDriver
                  duration={1000}
                  delay={300}
                  animation="fadeIn"
                  style={{
                    shadowRadius: 25,
                    shadowColor: '#000',
                    shadowOpacity: 0.05,
                    shadowOffset: { width: 0, height: 15 },
                    ...tw`bg-white items-center py-5 `,
                  }}
                >
                  <Text style={tw`mb-2.5 text-gray-600 font-header`}>
                    {locale.get('stay_details.start_date')}
                  </Text>
                  <Text style={tw`font-base text-3xl mb-1`}>{arrivalDay}</Text>
                  <Text style={tw`font-header uppercase`}>{arriveDate}</Text>
                </Animatable.View>
                <Animatable.View
                  useNativeDriver
                  duration={1000}
                  delay={400}
                  animation="fadeIn"
                  style={tw`h-[10%] border-l-1 left-[50%] border-gray-500`}
                />
                <Animatable.View
                  useNativeDriver
                  duration={1000}
                  delay={400}
                  animation="fadeIn"
                  style={tw`items-center`}
                >
                  <Text style={tw`my-2 text-gray-600 text-md font-header`}>
                    {locale.get('stay_details.length', { nights: String(stay.nights) })}
                  </Text>
                </Animatable.View>
                <Animatable.View
                  useNativeDriver
                  duration={1000}
                  delay={400}
                  animation="fadeIn"
                  style={tw`h-[10%] border-l-1 left-[50%] border-gray-500`}
                />
                <Animatable.View
                  useNativeDriver
                  duration={1000}
                  delay={500}
                  animation="fadeIn"
                  style={{
                    shadowRadius: 25,
                    shadowColor: '#000',
                    shadowOpacity: 0.05,
                    shadowOffset: { width: 0, height: 15 },
                    ...tw`bg-white items-center py-5 `,
                  }}
                >
                  <Text style={tw`mb-2.5 text-gray-600 font-header`}>
                    {locale.get('stay_details.end_date')}
                  </Text>
                  <Text style={tw`font-base text-3xl mb-1`}>{departDay}</Text>
                  <Text style={tw`font-header uppercase`}>{departDate}</Text>
                </Animatable.View>
              </View>
            </View>
            <View style={isWeb && tw`mt-[15%]`}>
              {upcomingStay && (
                <TouchableOpacity
                  onPress={handleModify}
                  style={tw`bg-primary p-4`}
                  activeOpacity={0.8}
                >
                  <Text style={tw`font-medium text-white text-sm text-center font-header`}>
                    {locale.get('stay_details.modify_button')}
                  </Text>
                </TouchableOpacity>
              )}
              {refundedStay && (
                <View style={tw`flex flex-row p-4 border-b-1 border-gray-400`}>
                  <View style={tw`flex-1`}>
                    <Text style={tw`mb-2.5 text-gray-600 font-header`}>
                      {locale.get('stay_details.status')}
                    </Text>
                    <Text style={tw`font-header`}>{locale.get('stay_details.refunded')}</Text>
                  </View>
                </View>
              )}
              {upcomingStay && (
                <TouchableOpacity style={tw`mt-7`} onPress={showModal} activeOpacity={0.8}>
                  <Text style={tw`text-center text-md text-rose-700 font-medium font-header`}>
                    {locale.get('stay_details.cancel_button')}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </Layout>
      </SafeAreaView>
    </>
  );
};
