import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { tw } from '../../../common/styles/tailwind';
import { Header } from '../../../common/components/Header';
import { ButtonType } from '../../../common/components/HeaderLeftButton';
import { useUserState } from '../../../common/state';
import { ProfileIconNames, UserFieldNames } from '../types';
import { SettingsItem } from '../components/SettingsItem';
import { ProfileStackParamList, RootStackParamList } from '../../../common/routes/types';
import { ProfileRoutes } from '../../../common/routes/routes';
import { Layout } from '../../../common/components/Layout';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';

export const ContactInformationScreen = () => {
  const { userInfo } = useUserState();
  const { locale } = useLocaleContext();
  const { navigate } =
    useNavigation<StackNavigationProp<RootStackParamList & ProfileStackParamList>>();

  const handleNavigate = (name: UserFieldNames) => {
    navigate(ProfileRoutes.EDIT_INFORMATION, { name });
  };

  const nameDescription = `${userInfo?.given_name} ${userInfo?.family_name}`;

  return (
    <SafeAreaView style={tw`w-full h-full bg-white`} edges={['top']}>
      <Header leftButtonType={ButtonType.BACK} title={locale.get('contact_info.header_title')} />
      <Layout scrollable webStyles="shadow-lg">
        <SettingsItem
          title={locale.get('contact_info.name')}
          description={nameDescription}
          iconName={ProfileIconNames.EDIT}
          onPress={() => handleNavigate(UserFieldNames.NAME)}
        />
        <SettingsItem
          title={locale.get('contact_info.phone')}
          description={userInfo?.phone || ''}
          iconName={ProfileIconNames.EDIT}
          onPress={() => handleNavigate(UserFieldNames.PHONE)}
        />
        <SettingsItem
          title={locale.get('contact_info.email')}
          description={userInfo?.email || ''}
          iconName={ProfileIconNames.LOCK}
          note={locale.get('contact_info.email_note')}
          onPress={() => null}
        />
        <SettingsItem
          title={locale.get('contact_info.country')}
          description={userInfo?.country || ''}
          iconName={ProfileIconNames.EDIT}
          onPress={() => handleNavigate(UserFieldNames.COUNTRY)}
        />
      </Layout>
    </SafeAreaView>
  );
};
