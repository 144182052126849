import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { tw } from '../styles/tailwind';
import { CustomTabBarLabel } from '../components/CustomTabBarLabel';
import { RootRoutes, RootTabRoutes } from './routes';
import { RootStackParamList, RootTabParamList } from './types';
import { HomeStackNav } from './home/HomeStackNav';
import { BookStackNav } from './book/BookStackNav';
import { StayStackNav } from './stay/StayStackNav';
import { ProfileStackNav } from './profile/ProfileStackNav';
import { useUserState } from '../state';
import { HomeIcon } from '../components/icons/HomeIcon';
import { HomeOutlineIcon } from '../components/icons/HomeOutlineIcon';
import { CalendarIcon } from '../components/icons/CalendarIcon';
import { CalendarOutlineIcon } from '../components/icons/CalendarOutlineIcon';
import { BriefcaseIcon } from '../components/icons/BriefcaseIcon';
import { BriefcaseOutlineIcon } from '../components/icons/BriefcaseOutlineIcon';
import { ProfileIcon } from '../components/icons/ProfileIcon';
import { ProfileOutlineIcon } from '../components/icons/ProfileOutlineIcon';
import { useLocaleContext } from '../providers/LocaleProvider';

const barStyle = Platform.select({ web: 'bg-white h-px', default: 'bg-white' });
const Tab = createMaterialBottomTabNavigator<RootTabParamList>();

const getTabBarIcon = (name: RootTabRoutes, focused: boolean) => {
  const activeColor = '#478529';
  const inactiveColor = '#828281';

  switch (true) {
    case name === RootTabRoutes.HOME_ROOT:
      return focused ? <HomeIcon color={activeColor} /> : <HomeOutlineIcon color={inactiveColor} />;
    case name === RootTabRoutes.BOOK_ROOT:
      if (focused) return <CalendarIcon color={activeColor} />;
      return <CalendarOutlineIcon color={inactiveColor} />;
    case name === RootTabRoutes.STAYS_ROOT:
      if (focused) return <BriefcaseIcon color={activeColor} />;
      return <BriefcaseOutlineIcon color={inactiveColor} />;
    case name === RootTabRoutes.PROFILE_ROOT:
      if (focused) return <ProfileIcon style={tw`w-6 h-6`} color={activeColor} />;
      return <ProfileOutlineIcon style={tw`w-6 h-6`} color={inactiveColor} />;
  }
};

export const HomeTabNav = () => {
  const { reset } = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { locale } = useLocaleContext();
  const { userInfo, accessToken, deleteUserInfo } = useUserState();

  useEffect(() => {
    if (!accessToken) {
      reset({ index: 0, routes: [{ name: RootRoutes.LOGIN }] });
      deleteUserInfo();
    }
  }, [accessToken, deleteUserInfo, reset]);

  return (
    <Tab.Navigator
      initialRouteName={RootTabRoutes.HOME_ROOT}
      activeColor={tw`bg-activeTab`.backgroundColor as string}
      inactiveColor={tw`bg-inactiveTab`.backgroundColor as string}
      barStyle={tw`${barStyle}`}
    >
      <Tab.Screen
        name={RootTabRoutes.HOME_ROOT}
        component={HomeStackNav}
        options={{
          tabBarLabel: (<CustomTabBarLabel label={locale.get('tabs.home')} />) as any,
          tabBarIcon: ({ focused }) => getTabBarIcon(RootTabRoutes.HOME_ROOT, focused),
        }}
      />
      {userInfo?.house_id && userInfo?.house_share ? (
        <>
          <Tab.Screen
            name={RootTabRoutes.BOOK_ROOT}
            component={BookStackNav}
            options={{
              tabBarLabel: (<CustomTabBarLabel label={locale.get('tabs.book')} />) as any,
              tabBarIcon: ({ focused }) => getTabBarIcon(RootTabRoutes.BOOK_ROOT, focused),
            }}
          />
          <Tab.Screen
            name={RootTabRoutes.STAYS_ROOT}
            component={StayStackNav}
            options={{
              tabBarLabel: (<CustomTabBarLabel label={locale.get('tabs.stays')} />) as any,
              tabBarIcon: ({ focused }) => getTabBarIcon(RootTabRoutes.STAYS_ROOT, focused),
            }}
          />
        </>
      ) : null}
      <Tab.Screen
        name={RootTabRoutes.PROFILE_ROOT}
        component={ProfileStackNav}
        options={{
          tabBarLabel: (<CustomTabBarLabel label={locale.get('tabs.profile')} />) as any,
          tabBarIcon: ({ focused }) => getTabBarIcon(RootTabRoutes.PROFILE_ROOT, focused),
        }}
      />
    </Tab.Navigator>
  );
};
