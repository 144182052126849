import { create } from 'zustand';
import { GetUserByIdQuery } from '@hamlet/graphql-urql';

import { IMessageState, MessageType, IUserState } from './types';

export const useUserState = create<IUserState>((set) => ({
  accessToken: '',
  userInfo: null,
  houseInfo: null,
  calendarData: { markedDates: [], activeSelectedDates: null },
  userLimits: null,
  trackingEnabled: false,
  setHouseInfo: (houseInfo) => set({ houseInfo }),
  setAccessToken: (token: string) => set({ accessToken: token }),
  setUserInfo: (data: GetUserByIdQuery['users_by_pk']) => set(() => ({ userInfo: data })),
  deleteUserInfo: () => set(() => ({ userInfo: null, houseInfo: null, accessToken: '' })),
  setCalendarData: (calendarData) => set({ calendarData }),
  setUserLimits: (userLimits) => set({ userLimits }),
  setTrackingEnabled: (trackingEnabled) => set({ trackingEnabled }),
}));

export const useMessageState = create<IMessageState>((set) => ({
  message: null,
  setInfoMessage: (message, description, options) =>
    set((state) => ({
      ...state,
      message: { type: MessageType.INFO, message, description, options },
    })),
  setSuccessMessage: (message, description, options) =>
    set((state) => ({
      ...state,
      message: { type: MessageType.SUCCESS, message, description, options },
    })),
  setWarningMessage: (message, description, options) =>
    set((state) => ({
      ...state,
      message: { type: MessageType.WARNING, message, description, options },
    })),
  setDangerMessage: (message, description, options) =>
    set((state) => ({
      ...state,
      message: { type: MessageType.DANGER, message, description, options },
    })),
  clearMessage: () => set((state) => ({ ...state, message: null })),
}));
