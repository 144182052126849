import React from 'react';
import { View } from 'react-native';

import { CustomBottomSheetModal } from '../../../common/components/CustomBottomSheetModal';
import { Layout } from '../../../common/components/Layout';
import { isWeb } from '../../../common/constants';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';
import { tw } from '../../../common/styles/tailwind';
import BookingRulesElement from './BookingRulesElement';

interface Props {
  closeModal: () => void;
  isVisible: boolean;
}

const BookingRulesModal: React.FC<Props> = ({ closeModal, isVisible }) => {
  const { locale } = useLocaleContext();

  return (
    <CustomBottomSheetModal
      closeModal={closeModal}
      isVisible={isVisible}
      snapPoints={['50%', '95%']}
      title={locale.get('modals.rules.title')}
    >
      <Layout
        scrollable={true}
        contentContainerStyle={isWeb ? tw`items-center` : tw`pb-[40px]`}
        commonStyles="px-4"
      >
        <View style={tw`w-full`}>
          <BookingRulesElement
            title={locale.get('modals.rules.general_title')}
            titleTag={locale.get('modals.rules.general_title_tag')}
            description={locale.get('modals.rules.general_description')}
          />
          <BookingRulesElement
            title={locale.get('modals.rules.special_title')}
            titleTag={locale.get('modals.rules.special_title_tag')}
            description={locale.get('modals.rules.special_description')}
          />
          <BookingRulesElement
            title={locale.get('modals.rules.peak_title')}
            titleTag={locale.get('modals.rules.peak_title_tag')}
            description={locale.get('modals.rules.peak_description')}
          />
          <BookingRulesElement
            title={locale.get('modals.rules.short_title')}
            titleTag={locale.get('modals.rules.short_title_tag')}
            description={locale.get('modals.rules.short_description')}
          />
          <BookingRulesElement
            title={locale.get('modals.rules.length_title')}
            description={locale.get('modals.rules.length_description')}
          />
          <BookingRulesElement
            title={locale.get('modals.rules.back_title')}
            description={locale.get('modals.rules.back_description')}
          />
          <BookingRulesElement
            title={locale.get('modals.rules.year_title')}
            description={locale.get('modals.rules.year_description')}
          />
          <BookingRulesElement
            title={locale.get('modals.rules.modify_title')}
            description={locale.get('modals.rules.modify_description')}
          />
          <BookingRulesElement
            title={locale.get('modals.rules.cancel_title')}
            description={locale.get('modals.rules.cancel_description')}
          />
        </View>
      </Layout>
    </CustomBottomSheetModal>
  );
};

export default BookingRulesModal;
