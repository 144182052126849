import React, { useState } from 'react';
import { Animated, Dimensions, FlatList, Image, Pressable } from 'react-native';
import { HouseImageFragment } from '@hamlet/graphql-urql';
import { Image as CachedImage } from 'react-native-expo-image-cache';
import { SlidingDot } from 'react-native-animated-pagination-dots';

import { tw } from '../../../common/styles/tailwind';
import { isWeb } from '../../../common/constants';
import { ImageView } from '../../../common/components/ImageView';

const { width: SCREEN_WIDTH } = Dimensions.get('window');
const MAX_BODY_WIDTH = 1024;
const HORIZONTAL_PADDINGS = SCREEN_WIDTH > MAX_BODY_WIDTH ? SCREEN_WIDTH - MAX_BODY_WIDTH : 0;
const IMAGE_WIDTH = SCREEN_WIDTH - HORIZONTAL_PADDINGS;

interface Props {
  data: HouseImageFragment[];
}

export const ImageComponent: React.FC<Props> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const flatListRef = React.useRef<FlatList | null>(null);
  const scrollX = React.useRef(new Animated.Value(0)).current;
  const renderItem = ({ item, index }: { item: HouseImageFragment; index: number }) => {
    const style = tw`w-full h-full`;

    const handlePressImage = () => {
      setActiveIndex(index);
      setVisible(true);
    };

    return (
      <Pressable style={tw`w-[${IMAGE_WIDTH}px] h-80`} onPress={handlePressImage}>
        {isWeb ? (
          <Image source={{ uri: item.url }} style={style} />
        ) : (
          <CachedImage uri={item.url} style={style} />
        )}
      </Pressable>
    );
  };

  const keyExtractor = (item: HouseImageFragment) => `house-image-${item.id}`;

  const handleImageChange = (imageIndex: number) => {
    flatListRef.current?.scrollToIndex({ index: imageIndex, animated: true });
  };

  return (
    <>
      <FlatList
        ref={flatListRef}
        data={data}
        pagingEnabled
        horizontal
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: scrollX } } }], {
          useNativeDriver: false,
        })}
        decelerationRate={'normal'}
        scrollEventThrottle={16}
        showsHorizontalScrollIndicator={false}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
      />
      {!isWeb && (
        <>
          <SlidingDot
            data={data}
            dotSize={10}
            scrollX={scrollX}
            marginHorizontal={5}
            dotStyle={tw`w-[10px] h-[10px] rounded-md mx-[5px] opacity-50 bg-[rgb(209,224,201)]`}
            slidingIndicatorStyle={tw`w-[10px] h-[10px] rounded-md mx-[5px] bg-primary`}
            containerStyle={tw`bottom-[10px]`}
          />
          <ImageView
            images={data.map((el) => ({ id: el.id, uri: el.url }))}
            presentationStyle="overFullScreen"
            imageIndex={activeIndex}
            onImageIndexChange={handleImageChange}
            visible={visible}
            onRequestClose={() => setVisible(false)}
          />
        </>
      )}
    </>
  );
};
