import React from 'react';
import { Text } from 'react-native';

import { useLocaleContext } from '../../../common/providers/LocaleProvider';
import { tw } from '../../../common/styles/tailwind';

interface Props {
  type: string;
}

export const ListHeader: React.FC<Props> = ({ type }) => {
  const { locale } = useLocaleContext();

  return (
    <Text style={tw`text-center font-header text-lightBlack text-xl mb-5`}>
      {locale.get(`stays.list_header.[${type}]`)}
    </Text>
  );
};
