import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '@hamlet/utils';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as WebBrowser from 'expo-web-browser';
import React, { useState } from 'react';
import { ImageBackground, Text, TouchableOpacity, View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { SafeAreaView } from 'react-native-safe-area-context';

import { ArrowIcon } from '../../../common/components/icons/ArrowIcon';
import { SmallLogoIcon } from '../../../common/components/icons/SmallLogoIcon';
import { ScreenLoader } from '../../../common/components/ScreenLoader';
import { isWeb } from '../../../common/constants';
import { useAuth0 } from '../../../common/hooks/auth0';
import { useToast } from '../../../common/hooks/useToast';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';
import { RootRoutes } from '../../../common/routes/routes';
import { RootStackParamList } from '../../../common/routes/types';
import { useUserState } from '../../../common/state';
import { tw } from '../../../common/styles/tailwind';
import {
  getCalendarData,
  getHouseDataByHouseId,
  getUserData,
  getUserLimits,
} from '../../../common/utils/utils';

WebBrowser.maybeCompleteAuthSession();

export const LoginScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { locale } = useLocaleContext();
  const toast = useToast();
  const { login } = useAuth0();
  const { setUserInfo, setHouseInfo, setCalendarData, setUserLimits, setAccessToken } =
    useUserState();
  const { setItem: setToken } = useAsyncStorage('jwtToken');
  const { reset } = useNavigation<StackNavigationProp<RootStackParamList>>();

  const setInitialData = async (userId: string, houseId: string | null, accessToken: string) => {
    const limitStatus = await getUserLimits(userId, accessToken);

    if (limitStatus) {
      setUserLimits(limitStatus);
    }

    if (!houseId) {
      return;
    }

    const houseInfo = await getHouseDataByHouseId(houseId, accessToken);
    const calendarData = await getCalendarData(houseId, userId, accessToken);

    if (calendarData) {
      setCalendarData(calendarData);
    }

    if (houseInfo) {
      setHouseInfo(houseInfo);
    }
  };

  const handleAuth = async () => {
    setIsLoading(true);
    try {
      const { tokenData, accessToken, tokenConfig } = await login();

      const userData = await getUserData(tokenData.userId, accessToken);

      await setToken(tokenConfig);
      setAccessToken(accessToken);
      if (userData) {
        setUserInfo(userData);
        await setInitialData(userData.id, userData.house_id, accessToken);
      }
      reset({ index: 0, routes: [{ name: RootRoutes.ROOT }] });
      toast.success(locale.get(SUCCESS_MESSAGES.login));
    } catch (err) {
      toast.danger(locale.get(ERROR_MESSAGES.default));
    }
    setIsLoading(false);
  };

  return (
    <Animatable.View
      style={tw`w-full h-full bg-white`}
      useNativeDriver
      duration={1000}
      animation="fadeIn"
    >
      {isLoading && <ScreenLoader />}
      <ImageBackground
        source={isWeb ? null : require('../../../../assets/bath.webp')}
        resizeMode="cover"
        style={tw`flex-1`}
      >
        <SafeAreaView style={tw`w-full px-5 h-full`}>
          <View style={tw`items-center justify-between h-full`}>
            <SmallLogoIcon
              width={155}
              color={isWeb ? 'black' : 'white'}
              style={tw`items-center mt-8`}
            />
            <View style={tw`w-full flex justify-end items-center`}>
              <Text
                style={tw`font-base text-[22px] text-center ${
                  isWeb ? 'text-black' : 'text-white'
                } font-bold`}
              >
                {locale.get('login.phrase_top')}
              </Text>
              <Text
                style={tw`font-base text-[22px] text-center ${
                  isWeb ? 'text-black' : 'text-white'
                } font-bold`}
              >
                {locale.get('login.phrase_bottom')}
              </Text>
              <TouchableOpacity
                style={tw`px-6 mb-7 mt-4 py-4 justify-center flex-row ${
                  isWeb ? 'min-w-[236px] w-full max-w-[40%]' : 'w-full'
                } rounded-lg bg-primary`}
                onPress={handleAuth}
                activeOpacity={0.6}
              >
                <Text style={tw`text-lg text-white font-header font-bold text-center`}>
                  {locale.get('login.bottom_button')}
                </Text>
                <View style={tw`ml-2 w-7 h-7`}>
                  <ArrowIcon color="white" />
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </SafeAreaView>
      </ImageBackground>
    </Animatable.View>
  );
};
