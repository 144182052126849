import React, { ComponentType, useCallback, useRef, useEffect, createElement } from 'react';
import { Animated, View, FlatList, ModalProps, Modal } from 'react-native';

import { ImageItem } from './components/ImageItem/ImageItem';
import { ImageDefaultHeader } from './components/ImageDefaultHeader';
import { StatusBarManager } from './components/StatusBarManager';
import { useAnimatedComponents } from './hooks/useAnimatedComponents';
import { useImageIndexChange } from './hooks/useImageIndexChange';
import { useRequestClose } from './hooks/useRequestClose';
import { tw } from '../../styles/tailwind';
import {
  DEFAULT_ANIMATION_TYPE,
  DEFAULT_BG_COLOR,
  DEFAULT_DELAY_LONG_PRESS,
  SCREEN,
} from './constants';
import { ImageSource } from './types';

interface Props {
  images: ImageSource[];
  imageIndex: number;
  visible: boolean;
  onRequestClose: () => void;
  onLongPress?: (image: ImageSource) => void;
  onImageIndexChange?: (imageIndex: number) => void;
  presentationStyle?: ModalProps['presentationStyle'];
  animationType?: ModalProps['animationType'];
  backgroundColor?: string;
  swipeToCloseEnabled?: boolean;
  doubleTapToZoomEnabled?: boolean;
  delayLongPress?: number;
  HeaderComponent?: ComponentType<{ imageIndex: number }>;
  FooterComponent?: ComponentType<{ imageIndex: number }>;
}

export const ImageView: React.FC<Props> = ({
  images,
  imageIndex,
  visible,
  onRequestClose,
  onLongPress = () => {},
  onImageIndexChange,
  animationType = DEFAULT_ANIMATION_TYPE,
  backgroundColor = DEFAULT_BG_COLOR,
  presentationStyle,
  swipeToCloseEnabled,
  doubleTapToZoomEnabled,
  delayLongPress = DEFAULT_DELAY_LONG_PRESS,
  HeaderComponent,
  FooterComponent,
}) => {
  const imageList = useRef<FlatList<ImageSource>>(null);
  const [opacity, onRequestCloseEnhanced] = useRequestClose(onRequestClose);
  const [currentImageIndex, onScroll] = useImageIndexChange(imageIndex, SCREEN);
  const [headerTransform, footerTransform, toggleBarsVisible] = useAnimatedComponents();

  const headerExists = typeof HeaderComponent !== 'undefined';
  const footerExists = typeof FooterComponent !== 'undefined';

  useEffect(() => {
    if (onImageIndexChange) {
      onImageIndexChange(currentImageIndex);
    }
  }, [currentImageIndex]);

  const onZoom = useCallback(
    (isScaled: boolean) => {
      // @ts-ignore
      imageList?.current?.setNativeProps({ scrollEnabled: !isScaled });
      toggleBarsVisible(!isScaled);
    },
    [imageList]
  );

  if (!visible) {
    return null;
  }

  const renderItem = ({ item: imageSrc }: { item: ImageSource }) => (
    <ImageItem
      onZoom={onZoom}
      imageSrc={imageSrc}
      onRequestClose={onRequestCloseEnhanced}
      onLongPress={onLongPress}
      delayLongPress={delayLongPress}
      swipeToCloseEnabled={swipeToCloseEnabled}
      doubleTapToZoomEnabled={doubleTapToZoomEnabled}
    />
  );

  const getItemLayout = (_: ImageSource[] | null | undefined, index: number) => ({
    length: SCREEN.width,
    offset: SCREEN.width * index,
    index,
  });

  const keyExtractor = (item: ImageSource) => `image-${item.id}`;

  return (
    <Modal
      key={imageIndex}
      transparent={presentationStyle === 'overFullScreen'}
      visible={visible}
      presentationStyle={presentationStyle}
      animationType={animationType}
      onRequestClose={onRequestCloseEnhanced}
      supportedOrientations={['portrait']}
      hardwareAccelerated
    >
      <StatusBarManager presentationStyle={presentationStyle} />
      <View style={[tw`flex bg-black flex-1`, { opacity, backgroundColor }]}>
        <Animated.View style={[tw`absolute w-full z-10 top-0`, { transform: headerTransform }]}>
          {headerExists ? (
            createElement(HeaderComponent, { imageIndex: currentImageIndex })
          ) : (
            <ImageDefaultHeader onRequestClose={onRequestCloseEnhanced} />
          )}
        </Animated.View>
        <FlatList
          ref={imageList}
          data={images}
          horizontal
          pagingEnabled
          windowSize={2}
          initialNumToRender={1}
          maxToRenderPerBatch={1}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          initialScrollIndex={imageIndex}
          getItemLayout={getItemLayout}
          renderItem={renderItem}
          onMomentumScrollEnd={onScroll}
          keyExtractor={keyExtractor}
        />
        {footerExists && (
          <Animated.View
            style={[tw`absolute w-full z-10 bottom-0`, { transform: footerTransform }]}
          >
            {createElement(FooterComponent, { imageIndex: currentImageIndex })}
          </Animated.View>
        )}
      </View>
    </Modal>
  );
};
