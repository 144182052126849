import axios, { AxiosHeaders, RawAxiosRequestHeaders } from 'axios';

export enum RequestMethods {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
}

type FetchProps = {
  endpoint: string;
  method?: RequestMethods;
  headers: RawAxiosRequestHeaders | AxiosHeaders;
  body?: Record<string, any>;
};

export async function fetchRequest<T>({
  endpoint,
  method = RequestMethods.GET,
  headers,
  body,
}: FetchProps): Promise<T> {
  try {
    const { data } = await axios({
      url: endpoint,
      method,
      data: body,
      headers,
    });

    return data;
  } catch (err) {
    console.log('error:', err);
    throw new Error(`${JSON.stringify(err)}`);
  }
}
