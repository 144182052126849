import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const ChevronRightIcon = (props: SvgProps) => (
  <Svg width={11} height={18} {...props}>
    <Path
      d="m.425 1.725 1.237-1.2L10.138 9 1.7 17.475l-1.238-1.2L7.737 9 .425 1.725Z"
      fill="currentColor"
    />
  </Svg>
);
