import React, { useCallback, useMemo } from 'react';
import { Platform, View } from 'react-native';
import { MarkedDates } from '@hamlet/graphql-urql';
import { CALENDAR_DATE_FORMAT, CURRENT_DATE, FUTURE_AVAILABLE_MONTHS } from '@hamlet/utils';
import { FlashList } from '@shopify/flash-list';
import { Calendar, DateData } from 'react-native-calendars';
import { DayProps } from 'react-native-calendars/src/calendar/day';
import dayjs from 'dayjs';

import { tw } from '../../../common/styles/tailwind';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';
import { MonthHeader } from './MonthHeader';

const isBrowser = Platform.OS === 'web';

interface Props {
  dayComponent: React.ComponentType<DayProps & { date?: DateData | undefined }>;
  markedDates: { [key: string]: MarkedDates };
}

export const CustomCalendarList: React.FC<Props> = ({ dayComponent, markedDates }) => {
  const { locale } = useLocaleContext();
  const items = useMemo(() => {
    const quantityOfMonths = FUTURE_AVAILABLE_MONTHS + 1;

    return [...Array(quantityOfMonths).keys()].reduce<string[]>((acc, months) => {
      const date = dayjs(CURRENT_DATE).add(months, 'months').format(CALENDAR_DATE_FORMAT);

      return [...acc, date];
    }, []);
  }, []);

  const getMarkedDatesForItem = useCallback(
    (item: string) => {
      const date = dayjs(item).format('YYYY-MM');

      return Object.keys(markedDates).reduce<{ [key: string]: MarkedDates }>((acc, markedDate) => {
        const convertedMarkedDate = dayjs(markedDate).format('YYYY-MM');

        if (convertedMarkedDate === date) {
          acc = { ...acc, [markedDate]: { ...markedDates[markedDate] } };
        }

        return acc;
      }, {});
    },
    [markedDates]
  );

  const renderItem = ({ item }: { item: string }) => {
    const markedData = getMarkedDatesForItem(item);

    return (
      <Calendar
        initialDate={item}
        minDate={dayjs(item).startOf('month').format()}
        maxDate={dayjs(item).endOf('month').format()}
        horizontal={false}
        style={tw`py-3 px-0 ${isBrowser ? 'mr-4' : ''}`}
        removeClippedSubviews={true}
        firstDay={1}
        hideDayNames={true}
        hideArrows={true}
        disableArrowLeft={true}
        hideExtraDays={true}
        disableArrowRight={true}
        theme={{ weekVerticalMargin: 0 }}
        disableAllTouchEventsForDisabledDays={true}
        customHeader={() => <MonthHeader current={item} locale={locale.language} />}
        disableAllTouchEventsForInactiveDays={true}
        dayComponent={dayComponent}
        markingType="custom"
        markedDates={markedData as any}
      />
    );
  };

  const keyExtractor = (date: string) => `date-${date}`;

  return (
    <View style={tw`flex h-full bg-white`}>
      <FlashList
        contentContainerStyle={isBrowser ? tw`pr-4 pb-28 bg-white` : tw`pb-28 bg-white`}
        data={items}
        extraData={markedDates}
        horizontal={false}
        onEndReachedThreshold={0.1}
        showsVerticalScrollIndicator={isBrowser}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        estimatedItemSize={352}
      />
    </View>
  );
};
