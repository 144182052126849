import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const BriefcaseIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 23 20" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 0c-.709 0-1.43.2-2 .612-.574.415-1 1.06-1 1.832V4h-3a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3h-3V2.444c0-.772-.426-1.417-1-1.832C14.93.201 14.209 0 13.5 0h-4Zm5 4V2.444c0-.003 0-.02-.02-.055a.526.526 0 0 0-.151-.155c-.18-.13-.477-.234-.829-.234h-4c-.352 0-.648.103-.829.234a.526.526 0 0 0-.151.155c-.02.034-.02.052-.02.055V4h6Z"
      fill="currentColor"
    />
  </Svg>
);
