import { useEffect } from 'react';
import { Platform, ModalProps, StatusBar } from 'react-native';

export const StatusBarManager = ({
  presentationStyle,
}: {
  presentationStyle?: ModalProps['presentationStyle'];
}) => {
  if (Platform.OS === 'ios' || presentationStyle !== 'overFullScreen') {
    return null;
  }

  StatusBar.setHidden(true);

  useEffect(() => {
    return () => StatusBar.setHidden(false);
  }, []);

  return null;
};
