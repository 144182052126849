import { Dimensions } from 'react-native';
import {
  DateStatuses,
  DateTypes,
  SeasonTypes,
  SelectedDates,
  Stay_Types_Enum,
} from '@hamlet/graphql-urql';
import { CalendarTypes } from '@hamlet/utils';

import { PolicyInfoTypes } from './types';

const { width: SCREEN_WIDTH } = Dimensions.get('window');
const MAX_BODY_WIDTH = 1024;
export const ITEM_WIDTH = SCREEN_WIDTH > MAX_BODY_WIDTH ? MAX_BODY_WIDTH / 7 : SCREEN_WIDTH / 7;

export const DEFAULT_SELECTED_VALUE = {
  date: '',
  dateType: DateTypes.RegularDate,
  seasonType: SeasonTypes.RegularSeason,
  status: DateStatuses.Free,
  specialDateName: '',
  startingStayDay: false,
  endingStayDay: false,
  startingSpecialDay: false,
  endingSpecialDay: false,
};

export const DEFAULT_SELECTED: SelectedDates = {
  start: DEFAULT_SELECTED_VALUE,
  end: DEFAULT_SELECTED_VALUE,
};

export const MODAL_WINDOW_DATA: {
  [key in PolicyInfoTypes]: { title: string; description: string };
} = {
  cancelation: {
    title: 'policy_modal.cancelation.title',
    description: 'policy_modal.cancelation.description',
  },
  [Stay_Types_Enum.PeakSeasonStay]: {
    title: 'policy_modal.peak_season_stay.title',
    description: 'policy_modal.peak_season_stay.description',
  },
  [Stay_Types_Enum.AdvanceStay]: {
    title: 'policy_modal.advance_stay.title',
    description: 'policy_modal.advance_stay.description',
  },
  [Stay_Types_Enum.PeakSpecialStay]: {
    title: 'policy_modal.peak_special_stay.title',
    description: 'policy_modal.peak_special_stay.description',
  },
  [Stay_Types_Enum.ShortNoticeStay]: {
    title: 'policy_modal.short_notice_stay.title',
    description: 'policy_modal.short_notice_stay.description',
  },
  [Stay_Types_Enum.SpecialStay]: {
    title: 'policy_modal.special_stay.title',
    description: 'policy_modal.special_stay.description',
  },
};

export const POLICY_TITLES: {
  [key in PolicyInfoTypes]: string;
} = {
  cancelation: 'policy_title.cancelation',
  [Stay_Types_Enum.PeakSeasonStay]: 'policy_title.peak_season_stay',
  [Stay_Types_Enum.AdvanceStay]: 'policy_title.advance_stay',
  [Stay_Types_Enum.PeakSpecialStay]: 'policy_title.peak_special_stay',
  [Stay_Types_Enum.ShortNoticeStay]: 'policy_title.short_notice_stay',
  [Stay_Types_Enum.SpecialStay]: 'policy_title.special_stay',
};

export const BOTTOM_BUTTON_TITLE = {
  [CalendarTypes.CREATE]: 'book.create_button',
  [CalendarTypes.EDIT]: 'book.edit_button',
};
