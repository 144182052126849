import { I18n } from 'i18n-js';

import en from '../../../assets/localize/en.json';
import no from '../../../assets/localize/no.json';

export enum Languages {
  EN = 'en',
  NO = 'no',
}

export type Key =
  | string
  | { key: string; options?: { [key: string]: undefined | string | number } };

export class Locale {
  private i18n: I18n;

  constructor(locale: Languages, resources: { [key in Languages]: any }) {
    this.i18n = new I18n(resources, {
      defaultLocale: Languages.EN,
      locale,
      enableFallback: true,
    });
  }

  public get(key: Key, options?: { [key: string]: string }) {
    if (typeof key === 'string') {
      return this.i18n.t(key, options);
    }

    return this.i18n.t(key.key, key.options);
  }

  public changeLanguage = (language: Languages) => {
    this.i18n.locale = language;
  };
}

export const locale = new Locale(Languages.EN, { [Languages.EN]: en, [Languages.NO]: no });
