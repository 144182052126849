import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const SmallLogoIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 180 94" width={180} height={94} {...props}>
    <Path
      d="M148.985 1.443v36.99h-19.57V1.442H98.4v36.99H71.63c0 6.495-.922 12.269-3.138 14.975L57.785 41.68c12.923-4.15 22.338-12.089 22.338-21.291C80.123 4.33 62.4 0 44.677 0c-22.892 0-33.23 7.759-33.23 21.291 0 7.398 7.568 14.976 12.922 20.75C10.154 46.37 0 55.213 0 67.843c0 10.465 8.308 25.26 36.923 25.26 10.892 0 22.708-3.247 28.246-5.954l4.062 4.511h60.184V55.032h19.57V91.66H180V1.443h-31.015ZM32.308 68.745C29.908 64.235 31.2 58.28 36 55.032l14.954 16.42c-4.246 2.526-15.323 3.428-18.646-2.707Zm14.769-38.974c-2.215-2.706-5.723-6.495-5.723-9.743 0-2.887 2.215-5.593 6.277-5.593 3.692 0 5.723 2.345 5.723 5.954 0 3.067-2.215 6.676-6.277 9.383Zm38.03 42.041c5.666-4.91 10.214-11.09 13.293-17.877v32.61L85.108 71.812Z"
      fill="currentColor"
    />
  </Svg>
);
