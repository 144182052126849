import React from 'react';
import { Dimensions, Pressable, Text, View } from 'react-native';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import classNames from 'classnames';

import { tw } from '../../../common/styles/tailwind';
import { ProfileIconNames } from '../types';

interface Props {
  title: string;
  description?: string;
  note?: string;
  iconName?: ProfileIconNames;
  containerStyle?: string;
  onPress: () => void;
}

const ICON_SIZE = 26;
const MAX_BODY_WIDTH = 1024;
const ICON_MARGIN = 10;
const HORIZONTAL_PADDING = 16;
const { width: SCREEN_WIDTH } = Dimensions.get('screen');

const UNAVAILABLE = ICON_SIZE + ICON_MARGIN + HORIZONTAL_PADDING * 2;
const DESCRIPTION_WIDTH =
  SCREEN_WIDTH > MAX_BODY_WIDTH ? MAX_BODY_WIDTH - UNAVAILABLE : SCREEN_WIDTH - UNAVAILABLE;

export const SettingsItem: React.FC<Props> = ({
  title,
  description,
  note,
  iconName,
  containerStyle = '',
  onPress,
}) => {
  const infoWrapper = classNames({
    'justify-center': !description,
    'justify-between': description,
  });

  return (
    <Pressable
      style={tw`py-4 mx-4 border-b-[0.3px] flex-row justify-between items-center ${containerStyle}`}
      onPress={onPress}
    >
      <View style={tw`${infoWrapper}`}>
        <Text style={tw`text-base text-gray-800 font-header`}>{title}</Text>
        {description && <Text style={tw`text-sm text-gray-600 font-header`}>{description}</Text>}
        {note && (
          <Text style={{ ...tw`text-xs font-header`, width: DESCRIPTION_WIDTH }}>{note}</Text>
        )}
      </View>
      {iconName && <MaterialIcons name={iconName} color="#6a6969" size={26} />}
    </Pressable>
  );
};
