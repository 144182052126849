import React from 'react';
import { Text } from 'react-native';
import { useForm } from 'react-hook-form';

import { tw } from '../../../../common/styles/tailwind';
import { Header } from '../../../../common/components/Header';
import { ButtonType } from '../../../../common/components/HeaderLeftButton';
import { useUserState } from '../../../../common/state';
import { FormFieldsData } from '../../../../common/types/types';
import { Input } from '../../../../common/components/Input';
import { InfoBlockWrapper } from '../../../home/components/InfoBlockWrapper';
import { Layout } from '../../../../common/components/Layout';
import { useLocaleContext } from '../../../../common/providers/LocaleProvider';

interface Props {
  handleSave: (user: FormFieldsData, resetFields: (user: FormFieldsData) => void) => Promise<void>;
}

export const EditNameForm: React.FC<Props> = ({ handleSave }) => {
  const { userInfo } = useUserState();
  const { locale } = useLocaleContext();
  const {
    control,
    formState: { isDirty },
    reset,
    handleSubmit,
  } = useForm<FormFieldsData>({
    defaultValues: {
      given_name: userInfo?.given_name || '',
      family_name: userInfo?.family_name || '',
    },
  });

  const handlePress = async (user: FormFieldsData) => {
    await handleSave(user, reset);
  };

  return (
    <>
      <Header
        leftButtonType={ButtonType.BACK}
        title={locale.get('contact_info.name')}
        rightIcon={
          <Text style={tw`font-header ${isDirty ? 'text-black' : 'text-gray-400'}`}>
            {locale.get('contact_info.header_right')}
          </Text>
        }
        disabledRightButton={!isDirty}
        showRightIcon
        onPressRightIcon={handleSubmit(handlePress)}
      />
      <Layout scrollable webStyles="shadow-lg">
        <InfoBlockWrapper>
          <Text style={tw`mt-4 mb-3 uppercase font-header`}>{locale.get('contact_info.name')}</Text>
          <Input name="given_name" control={control} />
          <Text style={tw`my-3 uppercase font-header`}>
            {locale.get('contact_info.family_name')}
          </Text>
          <Input name="family_name" control={control} />
        </InfoBlockWrapper>
      </Layout>
    </>
  );
};
