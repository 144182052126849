import React from 'react';
import { View, Text } from 'react-native';

import { tw } from '../../../common/styles/tailwind';
import { useLocaleContext } from '../../../common/providers/LocaleProvider';
import { ITEM_WIDTH } from '../constants';

const DAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export const CalendarHeader = () => {
  const { locale } = useLocaleContext();

  const renderItem = (key: string, index: number) => (
    <Text
      style={{ ...tw`w-full text-center text-sm font-header`, maxWidth: ITEM_WIDTH }}
      key={`day-${index}`}
    >
      {locale.get(`days.[${key}]`).slice(0, 3)}
    </Text>
  );

  return (
    <View style={tw`py-3.5 border-b-1 border-gray-300 bg-white flex-row w-full`}>
      {DAYS.map(renderItem)}
    </View>
  );
};
