import Constants from 'expo-constants';

interface Config {
  hasuraUri: string;
  auth0Domain: string;
  auth0ClientId: string;
  environment: string;
  auth0MgmtClientId: string;
  auth0MgmtClientSecret: string;
}

const getConfig = (): Config => ({
  hasuraUri:
    process.env.NEXT_PUBLIC_HASURA_GRAPHQL_URL || Constants?.expoConfig?.extra?.hasuraUri || '',
  auth0Domain: process.env.AUTH0_DOMAIN || Constants?.expoConfig?.extra?.auth0Domain || '',
  auth0ClientId: process.env.AUTH0_CLIENT_ID || Constants?.expoConfig?.extra?.auth0ClientId || '',
  environment: process.env.ENVIRONMENT || '',
  auth0MgmtClientId:
    process.env.AUTH0_MGMT_CLIENT_ID || Constants?.expoConfig?.extra?.auth0MgmtClientId || '',
  auth0MgmtClientSecret:
    process.env.AUTH0_MGMT_CLIENT_SECRET ||
    Constants?.expoConfig?.extra?.auth0MgmtClientSecret ||
    '',
});

const config = getConfig();

export default config;
