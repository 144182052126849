import React from 'react';
import { Linking, Text, TouchableOpacity, View, Image, SafeAreaView } from 'react-native';
import * as Animatable from 'react-native-animatable';
import * as WebBrowser from 'expo-web-browser';

import { Layout } from '../../../common/components/Layout';
import { isWeb } from '../../../common/constants';
import { tw } from '../../../common/styles/tailwind';
import { HorizontalLine } from '../../profile/components/HorizontalLine';
import { LinearGradient } from 'expo-linear-gradient';
import { RootTabRoutes } from '../../../common/routes/routes';
import { Header } from '../../../common/components/Header';

export const ContactInformation = () => {
  const openWebLink = async (link: string) => {
    try {
      await WebBrowser.openBrowserAsync(link);
    } catch (error) {}
  };

  return (
    <SafeAreaView style={tw`w-full bg-white`}>
      {isWeb && (
        <Header
          title="Home"
          showNavigationButtons={isWeb}
          activeButtonRoute={RootTabRoutes.HOME_ROOT}
        />
      )}
      <Layout
        scrollable
        withShadow
        contentContainerStyle={tw`pb-[50%] bg-[#E5EFFF]`}
        showsVerticalScrollIndicator={false}
      >
        <LinearGradient
          colors={['#FFF', '#E5EFFF']}
          style={{ position: 'absolute', height: '100%', width: '100%', bottom: 0, zIndex: 0 }}
        />
        <View style={tw`text-center flex flex-col p-4 w-full items-center`}>
          <Animatable.View
            useNativeDriver
            duration={1000}
            animation="fadeIn"
            style={tw`w-full flex items-center`}
          >
            <Image
              source={require('../../../../assets/hamlet_logo_full.png')}
              resizeMode="contain"
              style={tw`w-[${isWeb ? '30%' : '80%'}] h-[90px] mb-10`}
            />
          </Animatable.View>
          <View style={tw`mb-10`}>
            <Animatable.Text
              useNativeDriver
              duration={1000}
              delay={150}
              animation="fadeInDown"
              style={tw`font-base text-xl text-center`}
            >
              &Hamlet is co-ownership of real estate done right.
            </Animatable.Text>
            <Animatable.View useNativeDriver duration={900} animation="fadeInDown">
              <HorizontalLine style="mb-0.5" />
              <HorizontalLine style="" />
            </Animatable.View>
          </View>
          <Animatable.View
            useNativeDriver
            duration={1500}
            delay={300}
            animation="fadeIn"
            style={tw`mb-10 w-full items-center`}
          >
            <Text style={tw`font-signature text-center p-5 text-8xl`}>Explore</Text>
          </Animatable.View>
          <Animatable.View
            useNativeDriver
            duration={1500}
            delay={300}
            animation="fadeIn"
            style={tw`bg-thrid p-3 items-center`}
          >
            <Text style={tw`font-base text-xl text-center`}>
              To use the application, you must own a &Hamlet.
            </Text>
            <Text style={tw`font-base text-xl mt-5 mb-3 text-center`}>
              If you own a &Hamlet, please contact us at
            </Text>
            <TouchableOpacity onPress={() => Linking.openURL('mailto:hei@andhamlet.no')}>
              <Text style={{ ...tw`font-bold font-header text-lg underline` }}>
                hei@andhamlet.no
              </Text>
            </TouchableOpacity>
          </Animatable.View>
          <TouchableOpacity
            onPress={() => openWebLink('https://andhamlet.com')}
            style={tw`bg-primary rounded-md p-4 text-center w-[${isWeb ? '40%' : '100%'}] mt-10`}
          >
            <Text style={tw`font-header text-lg text-white text-center`}>Learn more</Text>
          </TouchableOpacity>
        </View>
      </Layout>
    </SafeAreaView>
  );
};
