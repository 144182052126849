import React from 'react';
import { TouchableOpacity, Text } from 'react-native';

import { tw } from '../../../common/styles/tailwind';

interface Props {
  title: string;
  isVisible: boolean;
  handlePress: () => void;
}

export const TopButton: React.FC<Props> = ({ title, isVisible, handlePress }) => {
  const display = isVisible ? 'flex' : 'hidden';

  return (
    <TouchableOpacity
      onPress={handlePress}
      style={tw`z-10 absolute right-4 top-[70px] px-3 py-2 rounded border-1 bg-white border-black ${display}`}
      activeOpacity={0.8}
    >
      <Text style={tw`font-base font-header`}>{title}</Text>
    </TouchableOpacity>
  );
};
