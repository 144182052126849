import React, { useState } from 'react';
import { View, Text, Switch, Platform } from 'react-native';
import { useForm } from 'react-hook-form';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { useDeleteUserByIdMutation } from '@hamlet/graphql-urql';
import { requestTrackingPermissionsAsync } from 'expo-tracking-transparency';
import { ERROR_MESSAGES, INFO_MESSAGES, SUCCESS_MESSAGES } from '@hamlet/utils';

import { tw } from '../../../../common/styles/tailwind';
import { Header } from '../../../../common/components/Header';
import { ButtonType } from '../../../../common/components/HeaderLeftButton';
import { useUserState } from '../../../../common/state';
import { InfoBlockWrapper } from '../../../home/components/InfoBlockWrapper';
import { SwitchInput } from '../../../../common/components/SwitchInput';
import { FormFieldsData } from '../../../../common/types/types';
import { ProfileIconNames } from '../../types';
import { Layout } from '../../../../common/components/Layout';
import { CustomModal } from '../../../../common/components/CustomModal';
import { useAuth0 } from '../../../../common/hooks/auth0';
import { isWeb } from '../../../../common/constants';
import { useToast } from '../../../../common/hooks/useToast';
import appConfig from '../../../../common/config/appConfig';
import { RootRoutes } from '../../../../common/routes/routes';
import { SettingsItem } from '../SettingsItem';
import { RootStackParamList } from '../../../../common/routes/types';
import { HorizontalLine } from '../HorizontalLine';
import { useLocaleContext } from '../../../../common/providers/LocaleProvider';
import { Languages } from '../../../../common/hooks/locale';
import { LanguageButton } from '../LanguageButton';

interface Props {
  handleSave: (user: FormFieldsData, resetFields: (user: FormFieldsData) => void) => Promise<void>;
}

export const SettingsForm: React.FC<Props> = ({ handleSave }) => {
  const [visible, changeVisible] = useState(false);
  const toast = useToast();
  const { locale } = useLocaleContext();
  const { deleteAuth0User } = useAuth0();
  const { reset } = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { userInfo, accessToken, trackingEnabled, deleteUserInfo, setTrackingEnabled } =
    useUserState();
  const [, deleteUserById] = useDeleteUserByIdMutation();
  const { setItem: setToken } = useAsyncStorage('jwtToken');
  const {
    control,
    formState: { isDirty },
    reset: resetData,
    handleSubmit,
  } = useForm<FormFieldsData>({
    defaultValues: {
      notifications_enabled: userInfo?.notifications_enabled || false,
    },
  });

  const handlePress = async (user: FormFieldsData) => {
    await handleSave(user, resetData);
  };

  const handleDeleteAccount = async () => {
    const userData = await deleteUserById({ id: userInfo?.id });

    if (!userData) {
      toast.danger(locale.get(ERROR_MESSAGES.default));
      return;
    }

    if (!isWeb || (isWeb && appConfig.environment !== 'development')) {
      await deleteAuth0User(accessToken);
    }

    locale.changeLanguage(Languages.EN);
    await setToken('');
    hideModal();
    toast.success(locale.get(SUCCESS_MESSAGES.deleteUser));
    reset({ index: 0, routes: [{ name: RootRoutes.LOGIN }] });
    deleteUserInfo();
  };

  const showModal = () => {
    changeVisible(true);
  };

  const hideModal = () => {
    changeVisible(false);
  };

  const updateTrackingStatus = async (enabled: boolean) => {
    if (Platform.OS !== 'ios') return;
    if (!enabled) {
      toast.info(locale.get(INFO_MESSAGES.settings));
      setTrackingEnabled(false);
      return;
    }
    try {
      const { status, granted } = await requestTrackingPermissionsAsync();
      setTrackingEnabled(status === 'granted');
      if (granted) {
        toast.success(locale.get(SUCCESS_MESSAGES.modified));
      } else {
        toast.info(locale.get(INFO_MESSAGES.trackingFailed));
      }
    } catch (error) {
      toast.danger(locale.get(ERROR_MESSAGES.default));
    }
  };

  return (
    <>
      <Header
        leftButtonType={ButtonType.BACK}
        title={locale.get('settings.header_title')}
        rightIcon={
          <Text style={tw`font-header ${isDirty ? 'text-black' : 'text-gray-400'}`}>
            {locale.get('settings.header_right')}
          </Text>
        }
        disabledRightButton={!isDirty}
        showRightIcon
        onPressRightIcon={handleSubmit(handlePress)}
      />
      <Layout scrollable webStyles="shadow-lg">
        <CustomModal
          rightButtonDanger
          visible={visible}
          handleLeftButton={hideModal}
          handleRightButton={handleDeleteAccount}
          leftButtonText={locale.get('settings.modal.cancel')}
          rightButtonText={locale.get('settings.modal.accept')}
          title={locale.get('settings.modal.title')}
          description={locale.get('settings.modal.description')}
        />
        <InfoBlockWrapper>
          <Text style={tw`mt-4 mb-3 font-header`}>{locale.get('settings.notification_title')}</Text>
          <View style={tw`flex flex-row justify-between pb-4 items-center`}>
            <Text style={tw`font-header`}>{locale.get('settings.notification_description')}</Text>
            <SwitchInput name="notifications_enabled" control={control} />
          </View>
        </InfoBlockWrapper>
        <HorizontalLine style="mt-5" />
        <InfoBlockWrapper>
          <Text style={tw`mt-5 mb-3 font-header`}>{locale.get('settings.privacy_title')}</Text>
          <View style={tw`flex flex-row justify-between items-center`}>
            <Text style={tw`font-header`}>{locale.get('settings.privacy_description')}</Text>
            <Switch onValueChange={updateTrackingStatus} value={trackingEnabled} />
          </View>
        </InfoBlockWrapper>
        <HorizontalLine style="mt-5" />
        <InfoBlockWrapper>
          <View style={tw`flex-row justify-between items-center pt-5`}>
            <Text style={tw`font-header`}>{locale.get('settings.language')}</Text>
            <View style={tw`flex-row`}>
              <LanguageButton
                onPress={locale.changeLanguage}
                type={Languages.EN}
                active={locale.language === Languages.EN}
              />
              <LanguageButton
                onPress={locale.changeLanguage}
                type={Languages.NO}
                active={locale.language === Languages.NO}
              />
            </View>
          </View>
        </InfoBlockWrapper>
        <HorizontalLine style="mt-5" />
        <SettingsItem
          title={locale.get('settings.delete_account')}
          iconName={ProfileIconNames.DELETE}
          containerStyle="border-b-[0px]"
          onPress={showModal}
        />
      </Layout>
    </>
  );
};
