import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const ProfileIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <Path
      d="M6.817 7.982C7.65 8.86 8.71 9.298 10 9.298s2.35-.438 3.183-1.316c.834-.877 1.25-1.994 1.25-3.35 0-1.357-.416-2.468-1.25-3.334C12.35.433 11.29 0 10 0S7.65.433 6.817 1.298c-.834.866-1.25 1.977-1.25 3.334 0 1.356.416 2.473 1.25 3.35ZM0 17.333V20h20v-2.667c0-.748-.189-1.397-.567-1.947a3.816 3.816 0 0 0-1.5-1.28c-1.4-.656-2.75-1.153-4.05-1.492-1.3-.34-2.594-.509-3.883-.509-1.289 0-2.583.164-3.883.492-1.3.327-2.661.818-4.084 1.473-.6.304-1.089.737-1.466 1.298C.189 15.93 0 16.585 0 17.333Z"
      fill="currentColor"
    />
  </Svg>
);
