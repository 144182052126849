import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const ProfileOutlineIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <Path
      d="M10 9.298c-1.289 0-2.35-.438-3.183-1.316-.834-.877-1.25-1.994-1.25-3.35 0-1.357.416-2.468 1.25-3.334C7.65.433 8.71 0 10 0s2.35.433 3.183 1.298c.834.866 1.25 1.977 1.25 3.334 0 1.356-.416 2.473-1.25 3.35C12.35 8.86 11.29 9.298 10 9.298ZM0 20v-2.667c0-.748.189-1.403.567-1.965a3.825 3.825 0 0 1 1.466-1.298c1.423-.655 2.784-1.146 4.084-1.473 1.3-.328 2.594-.492 3.883-.492 1.289 0 2.583.17 3.883.509 1.3.34 2.65.836 4.05 1.491a3.816 3.816 0 0 1 1.5 1.281c.378.55.567 1.199.567 1.947V20H0Zm1.5-1.579h17v-1.088c0-.35-.111-.696-.333-1.035a2.304 2.304 0 0 0-.867-.79c-1.311-.678-2.55-1.151-3.717-1.42A15.896 15.896 0 0 0 10 13.684c-1.222 0-2.428.135-3.617.404s-2.427.742-3.716 1.42c-.334.188-.611.451-.834.79-.222.34-.333.685-.333 1.035v1.088ZM10 7.72c.822 0 1.511-.292 2.067-.877.555-.585.833-1.322.833-2.21 0-.866-.278-1.591-.833-2.176-.556-.585-1.245-.877-2.067-.877-.822 0-1.511.292-2.067.877-.555.585-.833 1.31-.833 2.176 0 .888.278 1.625.833 2.21.556.585 1.245.877 2.067.877Z"
      fill="currentColor"
    />
  </Svg>
);
