import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import classNames from 'classnames';

import { RootTabRoutes } from '../routes/routes';
import { tw } from '../styles/tailwind';

interface Props {
  type: RootTabRoutes;
  title: string;
  activeButtonRoute: RootTabRoutes;
  onPress: (type: RootTabRoutes) => void;
}

export const NavigationHeaderButton: React.FC<Props> = ({
  type,
  title,
  activeButtonRoute,
  onPress,
}) => {
  const active = activeButtonRoute === type;

  const handlePress = () => {
    onPress(type);
  };

  const textStyle = classNames('hover:text-primary', {
    'text-primary': active,
    'text-gray-500': !active,
  });

  return (
    <TouchableOpacity onPress={handlePress} style={tw`mx-5`}>
      <Text style={tw`${textStyle}`}>{title}</Text>
    </TouchableOpacity>
  );
};
