import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const CalendarOutlineIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 21 22" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 1a1 1 0 1 0-2 0v1h-6V1a1 1 0 0 0-2 0v1h-2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3h-2V1Zm3 7V5a1 1 0 0 0-1-1h-2v1a1 1 0 1 1-2 0V4h-6v1a1 1 0 0 1-2 0V4h-2a1 1 0 0 0-1 1v3h16Zm-16 2h16v9a1 1 0 0 1-1 1h-14a1 1 0 0 1-1-1v-9Z"
      fill="currentColor"
    />
  </Svg>
);
