import React, { useCallback, useRef, useState } from 'react';
import { Animated, ScrollView, NativeScrollEvent, NativeSyntheticEvent } from 'react-native';

import { useImageDimensions } from '../../hooks/useImageDimensions';
import { usePanResponder } from '../../hooks/usePanResponder';
import { getImageStyles, getImageTransform } from '../../utils';
import { ImageSource } from '../../types';
import { ImageLoading } from './ImageLoading';
import { SWIPE_CLOSE_OFFSET, WINDOW } from '../../constants';

const SWIPE_CLOSE_VELOCITY = 1.75;

interface Props {
  imageSrc: ImageSource;
  onRequestClose: () => void;
  onZoom: (isZoomed: boolean) => void;
  onLongPress: (image: ImageSource) => void;
  delayLongPress: number;
  swipeToCloseEnabled?: boolean;
  doubleTapToZoomEnabled?: boolean;
}

export const ImageItem = React.memo(
  ({
    imageSrc,
    onZoom,
    onRequestClose,
    onLongPress,
    delayLongPress,
    swipeToCloseEnabled = true,
    doubleTapToZoomEnabled = true,
  }: Props) => {
    const imageContainer = useRef<ScrollView>(null);
    const imageDimensions = useImageDimensions(imageSrc);
    const [translate, scale] = getImageTransform(imageDimensions, WINDOW);
    const scrollValueY = new Animated.Value(0);
    const [isLoaded, setLoadEnd] = useState(false);

    const onLoaded = useCallback(() => setLoadEnd(true), []);
    const onZoomPerformed = useCallback(
      (isZoomed: boolean) => {
        onZoom(isZoomed);
        if (imageContainer?.current) {
          imageContainer.current.setNativeProps({
            scrollEnabled: !isZoomed,
          });
        }
      },
      [imageContainer]
    );

    const onLongPressHandler = useCallback(() => {
      onLongPress(imageSrc);
    }, [imageSrc, onLongPress]);

    const [panHandlers, scaleValue, translateValue] = usePanResponder({
      initialScale: scale || 1,
      initialTranslate: translate || { x: 0, y: 0 },
      onZoom: onZoomPerformed,
      doubleTapToZoomEnabled,
      onLongPress: onLongPressHandler,
      delayLongPress,
    });

    const imagesStyles = getImageStyles(imageDimensions, translateValue, scaleValue);
    const imageOpacity = scrollValueY.interpolate({
      inputRange: [-SWIPE_CLOSE_OFFSET, 0, SWIPE_CLOSE_OFFSET],
      outputRange: [0.7, 1, 0.7],
    });
    const imageStylesWithOpacity = { ...imagesStyles, opacity: imageOpacity };

    const onScrollEndDrag = ({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
      const velocityY = nativeEvent?.velocity?.y ?? 0;
      const offsetY = nativeEvent?.contentOffset?.y ?? 0;

      if (
        (Math.abs(velocityY) > SWIPE_CLOSE_VELOCITY && offsetY > SWIPE_CLOSE_OFFSET) ||
        offsetY > WINDOW.height / 2
      ) {
        onRequestClose();
      }
    };

    const onScroll = ({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
      const offsetY = nativeEvent?.contentOffset?.y ?? 0;

      scrollValueY.setValue(offsetY);
    };

    return (
      <ScrollView
        ref={imageContainer}
        style={{ width: WINDOW.width, height: WINDOW.height }}
        pagingEnabled
        nestedScrollEnabled
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ height: WINDOW.height * 2 }}
        scrollEnabled={swipeToCloseEnabled}
        {...(swipeToCloseEnabled && {
          onScroll,
          onScrollEndDrag,
        })}
      >
        <Animated.Image
          {...panHandlers}
          source={imageSrc}
          style={imageStylesWithOpacity}
          onLoad={onLoaded}
        />
        {(!isLoaded || !imageDimensions) && <ImageLoading />}
      </ScrollView>
    );
  }
);
