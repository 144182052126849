import React from 'react';
import { Text } from 'react-native';

import { tw } from '../styles/tailwind';

interface Props {
  label: string;
}

export const CustomTabBarLabel: React.FC<Props> = ({ label }) => {
  return <Text style={tw`font-header text-lightBlack text-xs leading-5`}>{label}</Text>;
};
