import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const ArrowIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <Path
      d="m14.1 17.275-.55-.525 4.4-4.4H4.6v-.775h13.35l-4.4-4.4.55-.525 5.3 5.325-5.3 5.3Z"
      fill="currentColor"
    />
  </Svg>
);
