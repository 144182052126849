import { Dimensions } from 'react-native';

export const HEADER_BUTTON_HIT_SLOP = { top: 16, left: 16, bottom: 16, right: 16 };

export const SCREEN = Dimensions.get('screen');
export const WINDOW = Dimensions.get('window');
export const MIN_WINDOW_DIMENSION = Math.min(WINDOW.width, WINDOW.height);

export const DEFAULT_ANIMATION_TYPE = 'fade';
export const DEFAULT_BG_COLOR = '#000';
export const DEFAULT_DELAY_LONG_PRESS = 800;

export const SWIPE_CLOSE_OFFSET = 75;
