import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';

import { Locale, Languages, locale, Key } from '../hooks/locale';

interface ContextValue {
  locale: {
    language: Languages;
    get: Locale['get'];
    changeLanguage: Locale['changeLanguage'];
  };
}

const LocaleContext = createContext<ContextValue>({
  locale: {
    language: Languages.EN,
    get: () => '',
    changeLanguage: () => null,
  },
});

export const useLocaleContext = (): ContextValue => useContext<ContextValue>(LocaleContext);

export const LocaleContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [language, setLanguage] = useState(Languages.EN);
  const { setItem, getItem } = useAsyncStorage('language');

  const changeLanguage = async (lng: Languages) => {
    setLanguage(lng);
    locale.changeLanguage(lng);
    await setItem(lng);
  };

  const setInitialData = async () => {
    const oldLanguage = await getItem();
    const initialLanguage = (oldLanguage as Languages) || Languages.EN;

    locale.changeLanguage(initialLanguage);
    setLanguage(initialLanguage);
  };

  useEffect(() => {
    setInitialData();
  }, []);

  const get = (key: Key, options?: { [key: string]: string }) => {
    return locale.get(key, options);
  };

  return (
    <LocaleContext.Provider value={{ locale: { language, get, changeLanguage } }}>
      {children}
    </LocaleContext.Provider>
  );
};
